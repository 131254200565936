.l_ReservationInfoCard_main{
    width: 100%;
    max-width: 475px;

    height: auto;
    
    display: flex;
    flex-direction: column;

    box-sizing: border-box;
}

.l_ReservationInfoCard__btn_container{
    width: 100%;
    height: auto;
    display: flex;
    align-items: center;
    justify-content: center;

    margin-top: 24px;

    &.hidden{
        opacity: 0;
    }
}