.l_OptionList_main {
    width: 100%;
    height: auto;
}

.l_OptionList_contentContainer{
    --l-gap: 10px;
    gap: var(--l-gap);

    width: 100%;

    display: flex;
    flex-direction: column;
}

@media (min-width: 700px) {
    .l_OptionList_contentContainer{
        --l-gap: 20px;
    }
}