.l_SelectedOptionEle_main{
    --l-padding: 12px 16px 12px 16px;
    --l-gap: 14px;
    --l-border-radius: 12px;

    display: flex;
    flex-direction: row;
    gap: var(--l-gap);
    align-items: center;

    padding: var(--l-padding);
    border-radius: var(--l-border-radius);
    background-color: #E6F3F4;
}

@media (min-width: 700px){
    .l_SelectedOptionEle_main{
        --l-padding: 12px 16px 12px 16px;
        --l-gap: 14px;
        --l-border-radius: 12px;
    }
}