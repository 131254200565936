.l_Flex_main{
    --l-main-margin-bottom: var(--g-content-margin-bottom);
    --l-main-gap: 23px;
    --l-main-padding: 24px 0 24px 0;

    width: 100%;
    height: auto;

    gap: var(--l-main-gap);

    display: flex;
    align-items: flex-start;
    flex-wrap: wrap;
    padding: var(--l-main-padding);

    margin-bottom: var(--l-main-margin-bottom);

    
    /* 내부 항목 자식 컴포넌트의 position:absolute; 등의 속성으로 Flex영역의 padding영역을 침범하는경우 가려지는것을 방지하고 화면에 보이게함 */
    overflow: visible;

    &.preset{
        &.ImageGallery{
            --l-main-gap: var(--g-flex-gap-select-product-uploaded-image-list);
            --l-main-margin-bottom: 0;
        }

        &.selected_theme_list{
            --l-main-gap: var(--g-flex-gap-selected-theme-list);
            --l-main-margin-bottom: 0;
        }

        &.all_theme_item_list{
            --l-main-gap: var(--g-flex-gap-all-theme-item-list);
            --l-main-margin-bottom: 0;
        }

        &.OptionList{
            --l-main-gap: 11px;
            --l-main-margin-bottom: 0;
        }

        &.photo_or_product_list{
            --l-main-gap: 14px;
            --l-main-margin-bottom: 0;
        }
    }
}

@media (min-width: 700px) {
    .l_Flex_main{
        &.preset{
            &.ImageGallery{
                --l-main-gap: 16px;
            }

            &.selected_theme_list{
                gap: var(--g-flex-gap-selected-theme-list);
            }

            &.all_theme_item_list{
                gap: 24px var(--g-flex-gap-all-theme-item-list);
            }

            &.OptionList{
                --l-main-gap: 12px;
            }

            &.product_list{
                --l-main-gap: 27px 14px;
            }
        }
    }
}