.l_TimeIcon_main{
    width: 20px;
    height: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.l_TimeIcon_icon_img{
    width: 100%;
    height: 100%;
    object-fit: contain;
}