.l_ImgItemCard_main{
    --l-main-width: 100%;

    position: relative;

    width: var(--l-main-width);

    display: flex;
    flex-direction: column;
    justify-content: center;

    &.preset{
        &.all_theme_list_item{
            --l-main-width: calc((100% - var(--g-flex-gap-selected-theme-list)) / 2);
        }

        &.selected_theme_item{
            --l-main-width: calc((100% - var(--g-flex-gap-selected-theme-list)) / 2);
        }
    }
}

.l_ImgItemCard__img_container{
    --l-img_container-width: 100%;

    width: var(--l-img_container-width);
    aspect-ratio: 4/3;
    height: auto;

    overflow: hidden;
    
    /* 패딩 및 보더를 포함하여 크기 계산 */
    box-sizing: border-box;

    display: flex;
    align-items: center;
    justify-content: center;

    &.is_highlight{
        border: 2px solid var(--g-color-mint);
    }

    &.preset{
        &.selected_theme_item{
            aspect-ratio: 1/1;
        }
        
        &.all_theme_list_item{
            aspect-ratio: 1/1;
        }
    }
}

.l_ImgItemCard_detailBtn{
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.l_ImgItemCard__children_container{
    width: 100%;
}

.l_fileName_container{
    --l-fileName-container-margin-top: 4px;
    margin-top: var(--l-fileName-container-margin-top);
}

@media (min-width: 700px){
    .l_ImgItemCard_main{
        --l-main-width: 320px;

        &.preset{
            &.all_theme_list_item{
                --l-main-width: 232px;
            }
            
            &.selectedt_theme_item{
                --l-main-width: calc((100% - var(--g-flex-gap-selected-theme-list)) / 2);
            }
        }
        
    }

    .l_fileName_container{
        --l-fileName-container-margin-top: 8px;
    }

    .l_ImgItemCard__detail_btn_container{
        --l-detail_btn_container-bottom: 20px;
    }
}