.l_PaymentHistory_main{
    --l-gap: 14px;

    width: 100%;
    height: 100%;
    overflow: auto;

    display: flex;
    flex-direction: column;
    gap: var(--l-gap);
}

@media (min-width: 700px) {
    .l_PaymentHistory_main{
        --l-gap: 0;
        
        /* 이 이하로 가로 크기가 줄어들면, 2중 스크롤바가 생길 수 있다. */
        min-width: 1220px;
    }
}