.l_DetailPageComfirmBtnContainer_main{
    position: sticky;
    left: 0;
    bottom: 40px;

    width: 100%;
    
    display: flex;
    flex-direction: column;
    gap: 8px;
}