.l_MainListItemCard_main{
    --l-gap: 14px;

    width: 100%;
    height: auto;
    display: flex;
    flex-direction: column;

    gap: var(--l-gap);
}

.l_MainListItemCard__img_container{
    aspect-ratio: 4/3;
}

.l_MainListItemCard__children_container{
    --l-children-container-padding: 14px 10px 14px 6px;

    width: 100%;
    height: auto;
    display: flex;
    flex-direction: column;

    padding: var(--l-children-container-padding);

    box-sizing: border-box;
}

@media (min-width: 700px){
    .l_MainListItemCard_main{
        --l-gap: 20px;

        width: 350px;
    }

    .l_MainListItemCard__children_container{
        --l-children-container-padding: 0 10px 0 10px;
    }
}