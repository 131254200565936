@charset "UTF-8";

:root{
    /* 색 */
    --g-color-primary: #26B6C1;
    --g-color-primary-10: rgba(38, 182, 193, 0.1);
    --g-color-secondary: #9AC3C6;
    --g-color-line: #D5DDDE;
    --g-color-red-gray: #E66E76;

    /* 콘텐츠 하단 간격 */
    --g-content-margin-bottom: 20px;

    /* Flex gap */
    --g-flex-gap-selected-theme-list: 10px;
    --g-flex-gap-all-theme-item-list: 10px;

    /* 제품 제작 신청 페이지에서 사용하는 gap */
    /* 모바일 화면에서 각 항목의 크기를 계산하는데 사용된다. */
    --g-flex-gap-select-product-uploaded-image-list: 11px;
    --g-flex-gap-select-product-option-list: 11px;

    /* ---------------------이전 스타일들------------------ */

    --g-color-background-mobile: #F5F6F9;

    --g-color-main_contents-background:#ffffff;
    --g-color-components-background: #f6f6f6;

    --g-color-mint:#26B6C1;
    /* --g-color-card-background: #ffffff; */
    --g-color-card-background-clear: #ffffff00;
    --g-color-navi-background: #F4F8F9;
    --g-color-header-background: #ffffff;
    --g-color-title-btn-enable: #404040;
    --g-color-title-btn-disable: #c6c6c6;

    --g-color-small-square-btn-background-disable: #EEEFEF;
    --g-color-small-square-btn-background-enable: #E3EDEF;
    --g-color-small-square-btn-text-disable: #7FAAB1;
    --g-color-small-square-btn-text-enable: #23B2C1;
    --g-color-small-square-btn-border-enable: #23B2C1;

    --g-color-text: #808285;
    --g-color-text-dark: #404040;
    --g-color-text-ligth-gray: #A8A8A8;

    --g-color-table-header: #f1f1f3;
    --g-color-table-header-th-text: #aaaaaa;
    --g-color-table-hover: #fbfbfb;

    --g-color-success: #07af01;
    --g-color-save: #07af01;
    --g-color-warning: #fa792c;
    --g-color-delete: #ff164b;
    --g-color-func: blueviolet;
    --g-color-disabled: #f1f1f3;
    --g-color-close: #b1b1b1;

    --g-body-width: 100vw;
    --g-body-height: 100dvh;

    --g-header-height: 50px;
    --g-header-padding-both-side: 20px;

    --g-main-contents-width: 100%;
    --g-main-contents-height: 100%;

    --g-main-contents-padding-top: 25px;
    --g-main-contents-padding-bottom: 25px;

    --g-font-size0: 12px;
    --g-font-size1: 14px;
    --g-font-size2: 15px;
    --g-font-size3: 16px;
    --g-font-size4: 17px;
    --g-font-size5: 18px;
    --g-font-size6: 20px;
    --g-font-size7: 24px;
    --g-font-title: 24px;

    --g-font-width-bold: 600;

    --g-icon-width: 26px;
    --g-icon-higth: 26px;

    --g-gap: 30px;
    --g-gap-grid: 20px;
    --g-gap-wrap: 10px;

    /* --g-card-outline-border-radius: 15px; */
    --g-card-img-border-radius: 12px;
    --g-card-img-small-border-radius: 10px;
    --g-square-btn-border-radius: 8px;
    --g-btn-border-radius: 4px;

    --g-box-shadow: 0px 1px 5px #707070;
    --g-box-shadow-navi: 1px 6px 5px #707070;
    --g-box-shadow-header: 1px 1px 5px #707070;

    --g-border-box-out-line-style: 1px solid #707070;

    /* --g-textediter-width: 1046px; */
    --g-textediter-width: 1112px;
    --g-textediter-height: 100%;
    --g-textediter-aspect-ratio: 1 / 1.414;

    --g-main-flex-left-wrap-width: 1046px;

    /* TitleBtnWrap의 외부영역에서의 gap */
    --g-title-btn-wrap-line-gap-mobile: 20px;
    --g-title-btn-wrap-line-gap-web: 16px;
    
    /* TitleBtnWrap의 내부영역에서의 gap */
    --g-title-btn-wrap-line-inline-box-gap-mobile: 14px;
    --g-title-btn-wrap-line-inline-box-gap-web: 20px;

    /* TitleBtnWrap의 줄과 줄 사이의 gap */
    --g-title-btn-wrap-line-betwin-lines-mobile: 8px;
    --g-title-btn-wrap-line-betwin-lines-web: 12px;
}

html, body{
    /* 페이지 자체가 마우스 드래그에 반응하여 당겨지는듯한 애니메이션을 막음 */
    overscroll-behavior-y: none;
    overscroll-behavior-x: none;
}

html {
    scroll-behavior: smooth;
    -ms-content-zooming: none;
    -ms-touch-action: pan-x pan-y;
}

body{
    margin: 0;
    background-color: var(--g-color-main_contents-background);
    font-family: 'Wanted Sans', Arial, sans-serif;
}

input{
    outline: none;
}

.g_click_event_item{
    cursor: pointer;

    &.not_allowed{
        cursor: not-allowed;
    }
}

.g_card.wide_card{
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: var(--g-color-components-background);
}

.g_display_none_object{
    display: none !important;
}

.g_display_none_object.mobile{
    display: none !important;
}

.g_display_none_object.mobile.flex{
    display: none !important;
}

.g_display_none_object.web{
    display: block !important;
}

.g_display_none_object.web.flex{
    display: flex !important;
}

.g_icon{
    display: flex;
    align-items: center;
    justify-content: center;
    width: var(--g-icon-width);
    height: var(--g-icon-width);
    background-color: #ffffff;
    box-sizing: border-box;
    border-radius: 1000px;
}

.g_icon.size_full{
    width: 100%;
}


.g_icon.img{
    width: var(--g-icon-width);
    /* overflow: auto; */
    overflow: hidden;
}

.g_icon.tooltip{
    width: calc(var(--g-icon-width) / 2);
}

.g_icon.toggle_btn{
    width: var(--g-icon-width);
}

.g_btn{
    color: #ffffff;
    background-color: var(--g-color-mint);
    border-radius: var(--g-btn-border-radius);
    height: 50px;
    width: 332px;
    box-sizing: border-box;
    border: none;
    cursor: pointer;

    display: flex;
    justify-content: center;
    align-items: center;
}

.g_btn.shape.oval{
    border: var(--g-border-box-out-line-style);
    border-radius: 30px;
    color: #4e4e4e;
    background-color: #ffffff;
    height: 30px;
    min-width: 100px;
    width: auto;
}

.g_btn.shape.square{
    width: 35px;
    height: 70px;
    background-color: var(--g-color-very-light-gray);
    color: var(--g-color-light-gray);
    border-radius: var(--g-btn-border-radius);
}

.g_btn.activated{
    background-color: #E8FDFF;
    color: var(--g-color-mint);
}

.g_btn.cancel{
    background-color: var(--g-color-warning);
    color: white;
}

.g_btn.disabled{
    background-color: #ebebeb;
    color: var(--g-color-title-btn-disable);
    cursor: not-allowed;
}

.g_btn.delete{
    background-color: var(--g-color-delete);
    color: white;
}

.g_btn.save{
    background-color: var(--g-color-save);
    color: white;
}

.g_btn.dev{
    background-color: var(--g-color-func);
    color: white;
}

.g_btn.close{
    background-color: var(--g-color-close);
    color: white;
}

.g_btn.size1{
    width: 136px;
    height: 48px;
}

.g_btn.size2{
    width: 280px;
    height: 45px;
}

.g_btn.size3{
    height: 50px;
    width: 332px;
}

.g_btn_wrap{
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    gap: var(--g-gap-grid);
}

.g_btn_wrap.justify_content.space_evenly{
    justify-content: space-evenly;
}

.g_btn_wrap.justify_content.start{
    justify-content: flex-start;
}

.g_btn_wrap.justify_content.end{
    justify-content: end;
}

.g_btn_wrap.justify_content.space_around{
    justify-content: space-around;
}

.g_btn_wrap.justify_content.space_between{
    justify-content: space-between;
}

.g_input{
    text-align: center;
    box-sizing: border-box;
}

.g_input.type.radio{
    margin: 0;
}

.g_label_title{
    font-size: 14px;
    box-sizing: border-box;
}

.g_input_wrap{
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;
    gap: var(--g-gap-wrap);
}

.g_text_wrap .title{
    min-width: 70px;
}

.g_text_wrap.direction_column{
    flex-direction: column;
}

.g_text_wrap.warning{
    background-color: var(--g-color-warning);
    border-radius: 1rem;
    padding: 0.2rem 0.4rem;
    color: white;
}

.g_text_wrap.success{
    background-color: var(--g-color-success);
    border-radius: 1rem;
    padding: 0.2rem 0.4rem;
    color: white;
}

.g_text_wrap.save{
    background-color: var(--g-color-save);
    border-radius: 1rem;
    padding: 0.2rem 0.4rem;
    color: white;
}

.g_text_wrap.delete{
    background-color: var(--g-color-delete);
    border-radius: 1rem;
    padding: 0.2rem 0.4rem;
    color: white;
}

.g_text_wrap.disabled{
    background-color: var(--g-color-disabled);
    border-radius: 1rem;
    padding: 0.2rem 0.4rem;
    color: white;
}

.g_modal__modal_close_btn{
    position: absolute;
    bottom: 100%;
    left: 100%;
}

.g_hover_animation{
    --duration: .44s;

    transform: translateY(var(--y)) translateZ(0);
    transition: transform var(--duration) ease, box-shadow var(--duration) ease;

    &:hover {
        --y: -5px;
        --shadow: var(--shadow-hover);
        span {
            --m: calc(var(--font-size) * -1);
        }
    }
}

/* 배경에 블러 효과 적용 */
.g_backdrop_filter{
    backdrop-filter: blur(3px);
    -webkit-backdrop-filter: blur(3px); /* Safari 호환 */    
}

/* SD (854×480) */
@media (min-width: 700px) {
    
    :root {
        --g-header-height: 100px;
        --g-header-padding-both-side: 30px;
        --g-main-contents-padding-top: 30px;
        --g-main-contents-padding-bottom: 30px;
        --g-icon-width: 50px;
        --g-icon-higth: 50px;

        --g-flex-gap-select-product-uploaded-image-list: 16px;
        --g-flex-gap-select-product-option-list: 12px;
    }

    .g_icon{
        box-shadow: var(--g-box-shadow);
    }

    .g_icon.size_small{
        width: calc(var(--g-icon-width) - 20px);
    }

    .g_icon.toggle_btn{
        box-shadow: none;
        background-color: rgba(0, 0, 0, 0);
    }


    .g_display_none_object.mobile{
        display: block !important;
    }

    .g_display_none_object.mobile.flex{
        display: flex !important;
    }

    .g_display_none_object.web{
        display: none !important;
    }

    .g_display_none_object.web.flex{
        display: none !important;
    }

    .g_btn{
        width: 250px;
        height: 50px;
    }

    .g_input{
        height: 40px;
    }

    .g_input.size.fixed{
        width: 300px;
        min-width: 300px;
        max-width: 300px;
    }

    .g_input.size.min_width{
        min-width: 300px;
    }

    .g_input.size.max_width{
        max-width: 300px;
    }

    .g_input.size.full{
        max-width: 100%;
    }

    .g_label_title{
        font-size: var(--g-font-size5);
    }

    .g_card_wrap{
        flex-direction: row;
        gap: 30px;
    }

    .g_text_wrap .title{
        min-width: 100px;
    }
}

@media print {
    .g_no_print {
        display: none !important;
    }
}