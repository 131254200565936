.l_advertising_banner_main{
    position: relative;

    width: 100%;
    aspect-ratio: 1 / 1;

    display: flex;
    align-items: center;
    justify-content: center;
    
    overflow: hidden;
}

.l_advertising_banner__img{
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.l_advertising_banner__black_wrap{
    position: absolute;
    width: 100%;
    height: 100%;
    background-color: rgb(0,0,0,0.5);

    display: flex;
    align-items: center;
    justify-content: center;

    z-index: 1;
}

.l_advertising_banner__move_btn_container{
    position: absolute;
    bottom: 18px;
    left: 22px;

    z-index: 2;
}

.l_advertising_banner__order_container{
    position: absolute;
    top: 18px;
    right: 22px;

    z-index: 2;
}

@media (min-width: 700px){
    .l_advertising_banner_main{
        width: 480px;
    }
}