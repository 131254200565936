.l_UserFunctionBtn_main{
    position: relative;

    display: flex;
    flex-direction: column;
}

.l_UserFunctionBtn_mainContainer{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
}

.l_UserFunctionBtn_iconContainer{
    width: 26px;
    height: 26px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.l_UserFunctionBtn_iconImg{
    width: 100%;
    height: 100%;
    object-fit: contain;
}

.l_UserFunctionBtn_userName{
    margin-left: 8px;
}