.l_BottomSheetOpenButton_main{
    position: absolute;
    bottom: 0;
    left: 0;

    width: 100%;
    margin-bottom: 54px;
    display: flex;
    align-items: center;
    justify-content: center;

    z-index: 1;
}