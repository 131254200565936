.l_MainHome_main{
    --l-height: auto;
    --l-flex-direction: column;
    --l-gap: 14px;

    width: 100%;
    height: var(--l-height);

    display: flex;
    flex-direction: var(--l-flex-direction);
    align-items: flex-start;
    gap: var(--l-gap);
}

@media (min-width: 700px){
    .l_MainHome_main{
        --l-height: 0;
        --l-flex-direction: row;
        --l-gap: 24px;

        flex-grow: 1;
    }

    .l_MainHome_main > * {
        flex-shrink: 0; /* 자식 요소가 크기를 줄이지 않도록 설정 */
    }
}