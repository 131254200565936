.l_TrashcanIcon_main{
    width: 16px;
    height: 16px;

    &.size{
        &.large{
            width: 24px;
            height: 24px;
        }

        &.small{
            width: 16px;
            height: 16px;
        }

        &.full_heigth{
            height: 100%;
            aspect-ratio: 1/1;
        }
    }
}

.l_TrashcanIcon_img{
    width: 100%;
    height: 100%;
    object-fit: contain;
}