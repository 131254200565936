.l_icon_button_check_main{
    display: flex;
    align-items: center;
    justify-content: center;

    width: 25px;
    aspect-ratio: 1 / 1;

    background-color: white;
    border-radius: 100%;
    overflow: hidden;

    &.size{
        &.small{
            width: 25px;
        }

        &.medium{
            width: 50px;
        }

        &.large{
            width: 65px;
        }
    }
}

.l_icon_button_check__img{
    width: 100%;
    height: 100%;
    object-fit: cover;
}

@media (min-width: 700px){
    .l_icon_button_check_main{
        width: 50px;
    }
}