.l_AccountPageContentsCardContainer_main{
    width: 100%;
    max-width: 432px;

    height: auto;
    display: flex;
    flex-direction: column;
    align-items: center;

    gap: 24px;
}

.l_AccountPageContentsCardContainer_mainWrapper{
    width: 100%;
    height: auto;
    background-color: white;

    display: flex;
    flex-direction: column;
    align-items: center;

    padding: 48px 24px 48px 24px;
    border-radius: 30px;

    box-sizing: border-box;
    
    gap: 34px;
}

.l_AccountPageContentsCardContainer_title{
    width: 100%;

    display: flex;
    align-items: center;
    justify-content: center;
}

.l_AccountPageContentsCardContainer_contentContainer{
    width: 100%;
}

.l_AccountPageContentsCardContainer_buttonContainer{
    width: 100%;
}