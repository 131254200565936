.l_radio_btn_wrap_main{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    gap: var(--g-gap-wrap);

    &.justify_content{
        &.flex_start{
            justify-content: flex-start;
        }

        &.flex_end{
            justify-content: flex-end;
        }

        &.center{
            justify-content: center;
        }

        &.space_around{
            justify-content: space-around;
        }

        &.space_between{
            justify-content: space-between;
        }

        &.space_evenly{
            justify-content: space-evenly;
        }
    }
}

.l_radio_btn_wrap__input_context{
    display: flex;
    align-items: center;
    justify-content: flex-start;
    gap: var(--g-gap-wrap);
}

.l_radio_btn_wrap__item{
    display: flex;
    justify-content: center;
    align-items: center;
    gap: var(--g-gap-wrap);
}