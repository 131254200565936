.l_MonthlyScheduleList_main{
    --l-gap: 14px;
    --l-height: 100%; 

    width: 100%;
    height: var(--l-height);
    max-height: 370px;
    display: flex;
    flex-direction: column;
    gap: var(--l-gap);
    overflow: auto;
}

@media (min-width: 700px) {
    .l_MonthlyScheduleList_main{
        --l-gap: 20px; 
        --l-height: 100%;
    }
}