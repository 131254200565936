.l_Cross_main {
    position: relative;
    width: 24px;
    height: 24px;
}

.l_Cross_main::before, 
.l_Cross_main::after {
    content: '';
    position: absolute;
    background-color: var(--g-color-secondary);
}

.l_Cross_main::before {
    width: 100%;
    height: 1px;
    top: 50%;
    left: 0;
    transform: translateY(-50%);
}

.l_Cross_main::after {
    width: 1px;
    height: 100%;
    left: 50%;
    top: 0;
    transform: translateX(-50%);
}