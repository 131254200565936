.l_SelectedThemeList_main{
    --l-main-gap: 8px;

    width: 100%;
    height: auto;

    display: flex;
    flex-direction: column;

    gap: var(--l-main-gap);
}

.l_SelectedThemeList_hoverContentContainer{
    display: flex;
    flex-direction: column;
    gap: 10px;
    align-items: center;
    justify-content: center;
}

@media (min-width: 700px) {
    .l_SelectedThemeList_main{
        --l-main-gap: 14px;
    }
}