.l_AddressInfo_main{
    width: 100%;
    display: grid;
    grid-template-areas:
        "recipientName    recipientPhonenum"
        "recipientAddress recipientAddress"
    ;
    grid-template-columns: 1fr 1fr;
    row-gap: 24px;
    column-gap: 14px;
}

.l_recipientName{
    grid-area: recipientName;
}

.l_recipientPhonenum{
    grid-area: recipientPhonenum;
}

.l_recipientAddress{
    grid-area: recipientAddress;
}

.l_StyleAdd_LabelAndContent{
    gap: 14px;
}