.l_ResetUserPassword_main{
    width: 100%;
    max-width: 432px;

    display:  flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    box-sizing: border-box;
}

.l_ResetUserPassword_mainContentContianer{
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;

    padding: 48px 24px 48px 24px;
    border-radius: 30px;
    
    background-color: white;
}

.l_ResetUserPassword_logoContainer_imgCover{
    width: 120px;
    height: auto;
    margin-bottom: 14px;
}

.l_ResetUserPassword_title{
    margin-bottom: 34px;
}

.l_ResetUserPassword_resultContainer{
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    gap: 20px;

    margin-bottom: 30px;
}

.l_ResetUserPassword_resultContainer_textContainer{
    display: flex;
    flex-direction: column;
    align-items: center;
}

.l_ResetUserPassword_inputContainer{
    width: 100%;

    display: flex;
    flex-direction: column;
    gap: var(--g-gap-wrap);
}

.l_ResetUserPassword_inputContainer_inputWrap{
    display: flex;
    flex-direction: column;
    gap: var(--g-gap-wrap);
}

.l_ResetUserPassword_btnContainer{
    width: 100%;

    display: flex;
    flex-direction: row;
    gap: var(--g-gap-wrap);
}