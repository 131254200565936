.l_BabyInfoInputContainer_InputContainer{
    width: 100%;
    display: grid;
    grid-template-areas: 
        "Name Name"
        "BirthDay BirthTime"
        "Tall Weigth"
    ;
    row-gap: 8px;
    column-gap: 14px;
    grid-template-columns: 1fr 1fr;
}