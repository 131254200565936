.l_Button_main{
    /* 변수 선언 영역 */
    /* 변수의 값을 상황에따라 바꾸는 형식으로 스타일을 변경하는 방식 적용. */

    /* 상황별 padding값 */
    --l-main-padding-normal: 15px 24px 14px 24px;
    --l-main-padding-popup: 16px 29px 16px 29px;
    --l-main-padding-filter-select: 6px 10px 6px 10px;
    --l-main-padding-filter-item: 12px 16px 12px 16px;
    --l-main-padding-filter-item-samll: 4px 14px 4px 14px;
    --l-main-padding-detail: 8px 12px 8px 14px;

    --l-main-border-mormal: none;
    --l-main-border-next-page: 1px solid var(--g-color-primary);
    --l-main-border-detail: 1px solid var(--g-color-secondary);
    --l-main-border-trashcan-icon-btn: 1px solid var(--g-color-red-gray);

    --l-main-border-radius-filter-item: 12px;
    --l-main-border-radius-filter-item-small: 8px;
    --l-main-border-radius-detail: 50px;

    --l-main-color-normal-gray: #F5F8F9;

    /* 최종적으로 적용하는 변수 */
    --l-main-padding: 18px 24px 18px 24px;

    /* 최종적으로 적용되는 border */
    --l-main-border: var(--l-main-border-mormal);

    /* 최종적으로 적용하는 변수 */
    --l-main-border-radius: 27px;

    --l-size-size1: 120px; 
    --l-size-size2: 219px;
    --l-size-size3: 100%;

    --l-size-page-move-width: 168px;

    --l-color-normal: var(--g-color-primary);
    /* 변수 선언 영역 끝 */

    width: auto;

    padding: var(--l-main-padding);

    /* 버튼에 아이콘을 출력하는 경우를 위해 */
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    gap: 4px;

    border: var(--l-main-border);
    border-radius: var(--l-main-border-radius);

    background-color: var(--l-color-normal);

    &.preset{
        /* 기본 스타일 */
        &.normal{
            --l-main-padding: var(--l-main-padding-normal);
        }

        &.normal_gray{
            --l-main-padding: var(--l-main-padding-normal);
            background-color: var(--l-main-color-normal-gray);
        }

        /* 팝업창에 나오는 버튼 */
        &.popup{
            --l-main-padding: var(--l-main-padding-popup);
        }

        /* 비활성화 */
        &.disabled{
            --l-main-padding: var(--l-main-padding-normal);
        }

        /* 필터 버튼 */
        &.filter{
            --l-main-padding: var(--l-main-padding-filter-select);
        }

        /* 필터 항목 아이템 - 큰버전 */
        &.filter_item{
            --l-main-padding: var(--l-main-padding-filter-item);
            --l-main-border-radius: var(--l-main-border-radius-filter-item);
        }

        /* 필더 항목 아이템 - 작은버전 */
        &.filter_item_small{
            --l-main-padding: var(--l-main-padding-filter-item-samll);
            --l-main-border-radius: var(--l-main-border-radius-filter-item-small);
        }

        /* 페이지 이동 버튼 */
        &.move_page{
            --l-main-padding: var(--l-main-padding-normal);
            --l-color-normal: #FFFFFF;
            --l-main-border: var(--l-main-border-next-page);

            width: var(--l-size-page-move-width);
            justify-content: space-between;
        }

        /* 상세정보 모달 출력버튼 */
        &.detail_modal{
            --l-main-padding: var(--l-main-padding-detail);
            --l-main-border-radius: var(--l-main-border-radius-detail);
            --l-main-border: var(--l-main-border-detail);
            background-color: #FFFFFF;
            justify-content: space-between;
        }

        /* 이미지 상세보기 버튼 */
        &.detail_view{
            --l-main-padding: var(--l-main-padding-detail);
            --l-main-border-radius: var(--l-main-border-radius-detail);
            --l-main-border: var(--l-main-border-detail);
            background-color: #FFFFFF;
            justify-content: space-between;
        }

        /* 제거 버튼(detail_view와 같은 스타일 다른색) */
        &.trashcan_icon_btn{
            --l-main-padding: var(--l-main-padding-detail);
            --l-main-border-radius: var(--l-main-border-radius-detail);
            --l-main-border: var(--l-main-border-trashcan-icon-btn);
            background-color: #FFFFFF;
            justify-content: space-between;
        }
    }

    &.size{
        &.auto{
            width: auto;
        }

        &.size1{
            width: var(--l-size-size1);
        }

        &.size2{
            width: var(--l-size-size2);
        }

        &.size3{
            width: var(--l-size-size3);
        }
    }

    &.disabled{
        background-color: var(--l-main-color-normal-gray);
    }
}


@media (min-width: 700px) {
    .l_Button_main{
        --l-main-padding-normal: 18px 24px 18px 24px;

        --l-main-border-radius: 30px;

        --l-size-size1: 180px; 
        --l-size-size2: 238px; 
        --l-size-size3: 100%;

        --l-size-page-move-width: var(--l-size-size3);
    }
}
