.l_BottomSheet_main{
    position: absolute;
    bottom: -100%;
    left: 50%;
    transform: translateX(-50%);

    width: 100%;
    height: auto;
    max-height: 87.3%;

    transition: bottom 0.3s ease-in-out;

    z-index: 5;

    display: flex;
    flex-direction: column;

    background-color: white;

    border-radius: 20px 24px 0 0;

    box-sizing: border-box;

    overflow: hidden;

    padding: 14px;

    &.open{
        bottom: 0;
    }

    &.min_heigth_full{
        height: 100%;
    }
}

.l_BottomSheet_childrenContainer{
    width: 100%;
    height: 100%;
    overflow: auto; 
}