.l_DownloadIcon_main{
    width: 26px;
    height: 26px;
    display: flex;
    align-items: center;
    justify-content: center;
    overflow: hidden;
}

.l_DownloadIcon__icon_img{
    width: 100%;
    height: 100%;
    object-fit: contain;
}