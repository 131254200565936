.l_MyPage_main{
    width: 100%;
    max-width: 432px;

    display:  flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    box-sizing: border-box;
}

.l_MyPage_mainContainer{
    width: 100%;
    display: flex;
    flex-direction: column;
}

.l_MyPage_localContainer{
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 14px;
}

.l_MyPage_inputContainer{
    width: 100%;

    display: flex;
    flex-direction: column;
    gap: var(--g-gap-wrap);
}

.l_MyPage_btnContainer{
    width: 100%;

    display: flex;
    flex-direction: row;
    gap: var(--g-gap-wrap);
}