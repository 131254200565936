.l_ConfrimBtnContainer_main{
    position: sticky;
    left: 0;
    bottom: 0;

    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    gap: 14px;
}