.l_viewer_toolbar_main{
    display: none;
}

@media (min-width: 700px) {
    .l_viewer_toolbar_main{
        width:  auto;
        height: auto;
     
        position: fixed;
        left: 50%;
        bottom: 80px;
        transform: translateX(-50%);
        background-color: rgba(0, 0, 0, 0.7);
        padding: 10px;
        border-radius: 8px;
        box-sizing: border-box;
        
        /* viewer모달의 z-index는 2015다. 만들어진 년도일까? */
        z-index: 3000; /* 모달 위에 출력되도록 z-index 설정 */
    
        display: flex;
        flex-direction: row;
        justify-content: space-between;

        gap: 20px;
    }
}