.l_ContractDocsImgViewIconBtn_main{
    --l-width: 30px;
    --l-height: 30px;

    width: var(--l-width);
    height: var(--l-height);
    display: flex;
    align-items: center;
    justify-content: center;
}

@media (min-width: 700px){
    .l_ContractDocsImgViewIconBtn_main{
        --l-width: 80px;
        --l-height: auto;
    }
}