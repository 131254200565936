.l_CalendarHeader_main{
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    justify-content: center;

    gap: 24px;
}

.l_CalendarHeader_moveBtnContainer{
    height: 1rem;
    aspect-ratio: 1 / 1;
    display: flex;
    align-items: center;
    justify-content: center;
}