.l_OptionEle_main{
    --l-width: calc((100% - var(--g-flex-gap-select-product-option-list)) / 2);
    width: var(--l-width);
    height: auto;

    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 8px;
}

@media (min-width: 700px) {
    .l_OptionEle_main{
        --l-width: 302px;
    }
}