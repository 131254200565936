.l_ReservationInfoCardChildren_main{
    width: 100%;
    height: auto;
    display: flex;
    flex-direction: column;
    
    gap: 14px;
}

.l_ReservationInfoCardChildren_notificationContainer{
    width: 100%;
    height: auto;
    display: flex;
    flex-direction: column;

    gap: 8px;
}

.l_ReservationInfoCardChildren_TextareaOverlaySetContainer{
    width: 100%;
    height: 103px;
}