.l_BabyInfoInput_main{
    width: 100%;
    height: auto;

    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 4px;

    &.Name{
        /* BabyInfoInputContainer내에서 grid배치에 사용되는 이름 */
        grid-area: Name;
    }

    &.BirthDay{
        /* BabyInfoInputContainer내에서 grid배치에 사용되는 이름 */
        grid-area: BirthDay;
    }

    &.BirthTime{
        /* BabyInfoInputContainer내에서 grid배치에 사용되는 이름 */
        grid-area: BirthTime;
    }

    &.Tall{
        /* BabyInfoInputContainer내에서 grid배치에 사용되는 이름 */
        grid-area: Tall;
    }

    &.Weigth{
        /* BabyInfoInputContainer내에서 grid배치에 사용되는 이름 */
        grid-area: Weigth;
    }
}