.l_QuantityControllBtnIcon_main{
    width: 34px;
    height: 34px;

    display: flex;
    align-items: center;
    justify-content: center;

    border-radius: 200px;

    background-color: #E6F3F4;

    &.disable{
        background-color: #F5F8F9;
    }
}