.l_AlertIcon_main{    
    --l-width: 22px;
    --l-height: 22px;

    position: relative;
    width: var(--l-width);
    height: var(--l-height);
}

.l_AlertIcon_img{
    width: 100%;
    height: 100%;
    object-fit: contain;
}

@media (min-width: 700px) {
    .l_AlertIcon_main{
        --l-width: 24px;
        --l-height: 24px;
    }
}