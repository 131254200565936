.l_MainPage_main{
    --l-main-contents-padding: 10px 14px 0 14px;
    --l-main-gap: 20px;

    position: relative;

    width: 100%;
    height: 100%;
    
    display: flex;
    flex-direction: column;
    gap: var(--l-main-gap);
    
    padding: var(--l-main-contents-padding);
    
    box-sizing: border-box;
    
    &.background_color{
        &.select_page{
            background-color: #EAF1F3;
        }
    }

    &.preset{
        &.ThreeSplitPages{
            /* 같은 ThreeSplitPages라고 하더라도 이 프리셋은 저장/수정같은 동작을 처리하는 페이지에 맞는 스타일 구성이다. */
            /* 단순 view페이지에서 사용하는 ThreeSplitPages에 적용하면 스타일이 맞지 않는 경우가 있다. */
            --l-main-contents-padding: 0 14px 0 14px;
        }
    }
}

.l_MainPage_childrenContainer{
    width: 100%;
    height: 0;
    flex-grow: 1;

    display: flex;
    flex-direction: column;
}

@media (min-width: 700px){
    .l_MainPage_main{
        --l-main-contents-padding: 20px 40px 0 40px;
        min-width: 1260px;

        &.preset{
            &.ThreeSplitPages{
                --l-main-contents-padding: 0 20px 0 20px;
            }
        }
    }
}