.l_ProductConfirm_rightContainerNodeContainer{
    width: auto;
    display: flex;
    flex-direction: column;
    gap: 24px;
}

.l_ProductConfirm_productInfoContainer{
    width: auto;
    display: flex;
    flex-direction: column;
    gap: 14px;
}