.l_card_container_main{
    --l-color-card-background: #FFFFFF;
    --l-color-card-outline: var(--g-color-line);

    --l-card-outline-border-radius: 20px;
    
    --l-card-padding: 16px 13px 16px 13px;

    position: relative;

    background-color: var(--l-color-card-background);
    border-radius: var(--l-card-outline-border-radius);

    border: 1px solid var(--l-color-card-outline);

    padding: var(--l-card-padding);
    box-sizing: border-box;
    
    &.padding{
        &.none{
            padding: 0;
        }
    }

    &.outline{
        &.none{
            border: none;
        }
    }

    &.borderRadius{
        &.radius0{
            border-radius: 0;
        }
    }

    &.preset{
        &.img{
            --l-card-outline-border-radius: 12px;
            --l-card-padding: 0;
            --l-color-card-outline: #EDEDED;
            
            width: 100%;
            aspect-ratio: 1/1;

            /* 이미지를 출력할 때 테두리에 border-radius가 적용되어 있으므로 영역을 벗어나는 부분은 숨김 */
            overflow: hidden;
        }

        &.thumbnail_img{
            --l-card-outline-border-radius: 12px;
            --l-card-padding: 0;
            
            width: 100%;
            aspect-ratio: 1/1;

            /* 이미지를 출력할 때 테두리에 border-radius가 적용되어 있으므로 영역을 벗어나는 부분은 숨김 */
            overflow: hidden;
        }

        &.infoCard{
            --l-card-padding: 14px 24px 40px 24px;
        }

        &.additionalInfoCard{
            --l-card-padding: 34px 24px 24px 24px;
        }

        &.mobilePopup{
            --l-card-padding: 14px 24px 40px 24px;
            --l-card-outline-border-radius: 20px 20px 0 0;
            
            width: 100%;
            height: 100%;
            /* height: auto; */
            /* max-height: 100%; */
        }


        &.drag_and_drop{
            --l-card-outline-border-radius: 20px;
            --l-color-card-background: #F5F8F9;
            border: none;
        }

        &.SelectProduct_SelectArea{
            --l-card-padding: 28px 24px 28px 24px;
        }
    }
}

.l_card_container_main.highlight::after{    
    content: '';
    position: absolute;
    top: 0px;
    right: 0px;
    bottom: 0px;
    left: 0px;
    border: 3px solid var(--g-color-primary);
    border-radius: inherit;
}

@media (min-width: 700px){
    .l_card_container_main{
        --l-card-outline-border-radius: 30px;
        --l-card-padding: 24px 20px 24px 20px;
    
        &.preset{
            &.img{
                --l-card-outline-border-radius: 20px;
                --l-card-padding: 0;
            }

            &.infoCard{
                --l-card-padding: 48px 40px 48px 40px;
            }

            &.SelectProduct_SelectArea{
                --l-card-padding: 46px 24px 46px 24px;
            }
        }
    }
}