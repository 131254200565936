.l_MonthlyScheduleChangeBtn_main{
    padding: 8px 14px 8px 14px;
    
    display: flex;
    align-items: center;

    gap: 4px;

    background-color: white;
    border-radius: 50px;
    border: 1px solid var(--g-color-secondary);

    box-sizing: border-box;

    height: 32px;
}

.l_MonthlyScheduleChangeBtn_btnText{
    font-size: 14px;
    line-height: 21px;
}

.l_MonthlyScheduleChangeBtn_iconContainer{
    width: 16px;
    height: 16px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.l_MonthlyScheduleChangeBtn_iconImg{
    width: 100%;
    height: 100%;
    object-fit: contain;
}