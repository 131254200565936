.l_MonthlySchedule_main{
    --l-gap: 8px;
    --l-width: 100%;

    width: var(--l-width);
    height: auto;

    display: flex;
    flex-direction: column;

    gap: var(--l-gap);
}

.l_MonthlySchedule_titleText{
    --l-font-size: 20px;
    --l-line-height: 30px;

    font-size: var(--l-font-size);
    line-height: var(--l-line-height);
}

.l_MonthlySchedule_headerContainer{
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.l_MonthlySchedule_contentContainer{
    --l-height: auto;

    width: 100%;
    height: var(--l-height);
}

@media (min-width: 700px) {
    .l_MonthlySchedule_main{
        --l-gap: 10px;
        --l-width: 480px;

        aspect-ratio: 1/1;
    }

    .l_MonthlySchedule_titleText{
        --l-font-size: 24px;
        --l-line-height: 29px;
    }
    
    .l_MonthlySchedule_contentContainer{
        --l-height: 0;
        flex-grow: 1;
    }
}