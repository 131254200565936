.l_click_encourage_container{
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;

    border-radius: 14px;
    border: 1px solid #F2F2F2;

    box-sizing: border-box;

    background-color: #F5F8F9;
}