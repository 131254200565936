.l_ProductInfoCard_main{
    --l-width: 100;

    width: var(--l-width);
    height: auto;   
}

.l_ProductInfoCard_selectedOptionContainer{
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    flex-wrap: wrap;
    gap: 4px;
}

@media (min-width: 700px){
    .l_ProductInfoCard_main{
        --l-width: 432px;
    }
}