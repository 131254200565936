.l_MonthlyScheduleListItem_main{
    --l-height: 50px;
    --l-gap: 20px;

    width: 100%;
    height: var(--l-height);
    display: flex;
    flex-direction: row;
    gap: var(--l-gap);
}

.l_MonthlyScheduleListItem_dateText{
    --l-height: 50px;
    
    width: 80px;
    height: var(--l-height);
    padding: 14px;
    border-radius: 12px;

    display: flex;
    align-items: center;
    justify-content: center;

    box-sizing: border-box;

    background-color: #F5F8F9;
}

.l_MonthlyScheduleListItem_infoContainer{
    width: 0;
    flex-grow: 1;
    height: 100%;

    display: flex;
    flex-direction: column;
    justify-content: space-around;
}

.l_shootTitleText{
    --l-font-size: 16px;
    --l-line-height: 24px;

    font-size: var(--l-font-size);
    line-height: var(--l-line-height);
}

.l_dateFormatText{
    --l-font-size: 14px;
    --l-line-height: 21px;

    font-size: var(--l-font-size);
    line-height: var(--l-line-height);
}

@media (min-width: 700px) {
    .l_MonthlyScheduleListItem_main{
        --l-height: 56px;
        --l-gap: 34px;
    }

    .l_MonthlyScheduleListItem_dateText{
        --l-height: 56px;
    }

    .l_shootTitleText{
        --l-font-size: 18px;
        --l-line-height: 27px;
    }
    
    .l_dateFormatText{
        --l-font-size: 16px;
        --l-line-height: 24px;
    }
}