.l_ExtensionContainer_main{
    --l-display: none;

    position: absolute;
    top: 100%;
    right: 0;

    display: var(--l-display);
    flex-direction: column;
    gap: 4px;
    
    background-color: white;
    border-radius: 12px;

    box-sizing: border-box;

    overflow: hidden;
    padding: 8px;
    border: 1px solid #D5DDDE;

    z-index: 5;

    &.extension{
        --l-display: flex;
    }
}