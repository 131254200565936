.l_badge_main{
    width: auto;
    height: auto;
    position: relative;
}

.l_bage_mark_container{
    position: absolute;
    z-index: 1;

    top: 0;
    right: 0;
    transform: translate(50%, -50%);
}

.l_bage_children_container{
    width: auto;
    height: auto;
}