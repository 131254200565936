.l_loop_animation_modal_main{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-items: center;
    gap: var(--g-gap);

    position: relative;
}

.l_loop_animation_modal__text_container{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-items: center;
    gap: var(--g-gap-wrap);
    color: white;
}

.l_close_btn{
    position: absolute;
    bottom: 110%;
    left: 110%;
}