.l_TriangleIcon_main{
    --l-color: black;
    --l-harf-bottom: 6px;
    --l-height: 8px;

    width: 0;
    height: 0;

    /* 기본 화살표 방향은 아래로 */
    border-left: var(--l-harf-bottom) solid transparent;
    border-right: var(--l-harf-bottom) solid transparent;
    border-top: var(--l-height) solid var(--l-color);

    &.size{
        &.small{
            --l-harf-bottom: 4.5px;
            --l-height: 6px;
        }

        &.middle{
            --l-harf-bottom: 6px;
            --l-height: 8px;
        }
    }

    &.color{
        &.black{
            --l-color: black;
        }

        &.gray{
            --l-color: #919191;
        }
    }

    &.direction{
        &.top{
            border-left: var(--l-harf-bottom) solid transparent;
            border-right: var(--l-harf-bottom) solid transparent;
            border-bottom: var(--l-height) solid var(--l-color);
            
            border-top: none;
        }

        &.right{
            border-top: var(--l-harf-bottom) solid transparent;
            border-bottom: var(--l-harf-bottom) solid transparent;
            border-left: var(--l-height) solid var(--l-color);

            border-right: none;
        }

        &.bottom{
            border-left: var(--l-harf-bottom) solid transparent;
            border-right: var(--l-harf-bottom) solid transparent;
            border-top: var(--l-height) solid var(--l-color);

            border-bottom: none;
        }

        &.left{
            border-top: var(--l-harf-bottom) solid transparent;
            border-bottom: var(--l-harf-bottom) solid transparent;
            border-right: var(--l-height) solid var(--l-color);

            border-left: none;
        }
    }
}