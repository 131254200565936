.l_UploadImageContainerViewMode_main{
    --l-gap: 10px;

    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    box-sizing: border-box;

    gap: var(--l-gap);
}

.l_UploadImageContainerViewMode_header{
    width: 100%;
    display: flex;
    align-items: flex-end;
    flex-direction: row;
    justify-content: space-between;
    flex-wrap: wrap;
    gap: var(--g-gap-grid);

    position: sticky;
    top: 0;
    background-color: white;

    z-index: 3;
}

.l_UploadImageContainerViewMode_header_left{
    --l-gap: 4px;

    display: flex;
    flex-direction: row;
    align-items: center;
    flex-wrap: wrap;
    gap: var(--l-gap);
}

.l_UploadImageContainerViewMode_header_right{
    display: flex;
    flex-direction: row;
    align-items: center;
    flex-wrap: wrap;
    gap: 4px;
}

@media (min-width: 700px) {
    .l_UploadImageContainerViewMode_main{
        --l-gap: 20px;
    }

    .l_UploadImageContainerViewMode_header_left{
        --l-gap: 15px;
    }
}