.l_TextareaViewDiv_main{
    border-radius: 14px;
    border: 1px solid #F2F2F2;

    width: 100%;
    max-width: 460px;
    height: 100%;

    box-sizing: border-box;

    overflow: hidden;

    /* Text 컴포넌트의 가장 작은 폰트사이즈와 일치시킨다. */
    font-size: 14px;
}

.l_TextareaViewDiv_pre{
    width: 100%;
    height: auto;
    white-space: pre-wrap;
    word-break: break-all;

    margin: 0;
    padding: 16px;
    box-sizing: border-box;
}