.l_DeliveryAddressList_main{
    --l-width: 100%;

    width: var(--l-width);
    display: flex;
    flex-direction: column;
    gap: 24px;
}

.l_DeliveryAddressList_listContainer{
    display: flex;
    flex-direction: column;
    gap: 24px;
}

@media (min-width: 700px) {
    .l_DeliveryAddressList_main{
        --l-width: 460px;
    }
}