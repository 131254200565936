.l_video_player_main{
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.l_video_player__video{
    width: 100%;
    height: 100%;
    object-fit: contain;

    display: flex;
    align-items: center;
    justify-content: center;
}