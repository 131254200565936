.l_setting_img_width_main{
    display: flex;
    flex-direction: column;
    gap: var(--g-gap);
}

.l_setting_img_width__input_container{
    display: flex;
    flex-direction: column;
    gap: var(--g-gap-grid);
}

.l_setting_img_width__input_container__input_block{
    display: flex;
    flex-direction: column;
}

.l_setting_img_width__btn_container{
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;

    gap: var(--g-gap);
}