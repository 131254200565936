.l_ThemeBook_main{
    --l-gap: 8px;

    width: 100%;
    height: 0;
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    gap: var(--g-gap);
}

.l_ThemeBook_contents{
    width: 100%;
    height: 0;
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    gap: var(--g-gap);

    overflow: auto;
}

.l_ThemeBook_adminFilterContainer{
    position: sticky;
    left: 0;
    top: 0;
    
    width: 100%;
    background-color: white;
    z-index: 1;
}

.l_themeSelecetContents_filtedSubThemeListContainer{
    --l-filtedSubThemeListContainer-padding: 0px;

    /* ThemeItemCard 컴포넌트에서 사용된다.*/
    --g-filtedSubThemeListContainer-gap-x: 11px;
    --l-filtedSubThemeListContainer-gap-y: 14px;

    gap: var(--l-filtedSubThemeListContainer-gap-y) var(--g-filtedSubThemeListContainer-gap-x);
    padding: var(--l-filtedSubThemeListContainer-padding);
}

.l_themeSelecetContents_btnContainer{
    position: sticky;
    bottom: 0;
    width: 100%;
    box-shadow: 0px -6px 10px rgba(0, 0, 0, 0.15);
}

@media (min-width: 700px) {
    .l_ThemeBook_main{
        --l-gap: 40px;
    }

    .l_themeSelecetContents_filtedSubThemeListContainer{
        --l-filtedSubThemeListContainer-padding: 0 0 40px 0;
        --g-filtedSubThemeListContainer-gap-x: 16px;
        --l-filtedSubThemeListContainer-gap-y: 24px;
    }
}