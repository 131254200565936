.l_ContractId_main{
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: flex-start;
}

@media (min-width: 700px) {
    .l_ContractId_main{
        min-width: 120px;
    }
}