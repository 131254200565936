.l_MaintenancePage_main{
    text-align: center;
}

.l_h1{
    font-size: 2.5em;
    margin-bottom: 0.5em;
    text-wrap: pretty;
}

.l_p{
    font-size: 1.2em;
    text-wrap: pretty;
}