.l_Overlay_main{
    --l-align-items: flex-end;

    position: fixed;
    top: 0;
    left: 0;

    width: 0;
    height: 0;

    overflow: hidden;

    display: flex;

    /* BottomSheet가 사용되므로 아래로 정렬시킨다. */
    /* 라고 위에서 설명했지만, 사실 BottomSheet는 애니메이션으로인해 absolute를 유지중이다. */
    align-items: var(--l-align-items);
    justify-content: center;

    background-color: rgba(19, 19, 19, 0.5);

    z-index: 5;

    &.open{
        width: 100%;
        height: 100%;
    }
}

@media (min-width: 700px){
    .l_Overlay_main{
        /* Modal이 사용되므로 가운데로 정렬시킨다. */
        --l-align-items: center;
    }
}