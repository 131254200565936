.l_MorePayInfo_main{
    --l-padding: 20px 0 20px 0;
    --l-border-radius: 0 0 20px 20px;
    --l-border: 1px solid #D5DDDE;
    --l-justify-content: center;

    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: var(--l-justify-content);
    padding: var(--l-padding);
    box-sizing: border-box;
    border-radius: var(--l-border-radius);
    border: var(--l-border);

    &.open{
        --l-padding: 20px 0 0 0;
    }
}

.l_TextContainer{
    --l-gap: 8px;
    --l-margin-bottom: 0;

    display: flex;
    align-items: center;
    justify-content: center;
    gap: var(--l-gap);
    margin-bottom: var(--l-margin-bottom);

    &.open{
        --l-margin-bottom: 20px;
    }
}

.l_ExtentContainer{
    --l-height: 0;

    width: 100%;
    height: var(--l-height);
    overflow: hidden;

    &.open{
        --l-height: auto;
    }
}

@media (min-width: 700px) {
    .l_MorePayInfo_main{
        --l-padding: 0;
        --l-border-radius: none;
        --l-border: none;
        --l-justify-content: flex-end;

        min-width: 160px;

        &.open{
            --l-padding: 0;
        }
    }
    
    .l_TextContainer{        
        --l-gap: 14px;
        --l-margin-bottom: 0;

        &.open{
            --l-margin-bottom: 0;
        }
    }
}