.l_redirect_to_another_browser_main{
    width: 100%;
    height: 100%;

    display: flex;
    align-items: center;
    justify-content: center;

    .l_redirect_to_another_browser__content_container{
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;

        background-color: white;

        padding: 20px;
        box-sizing: border-box;
        border-radius: 10px;

        .l_redirect_to_another_browser__content_container__text_container{
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
        }

        .l_redirect_to_another_browser__content_container__btn_container{
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
        }
    }
}