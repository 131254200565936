.l_WrokRequiredNotification_main{
    --l-width: 100%;
    --l-aspect-ratio: 347/379; 
    --l-row-gap: 11px;

    width: var(--l-width);
    aspect-ratio: var(--l-aspect-ratio);

    display: grid;
    row-gap: var(--l-row-gap);
    column-gap: 14px;

    grid-template-columns: repeat(2, 1fr);
    grid-auto-rows: repeat(3, 1fr);

    grid-template-areas:
        "headerItme headerItme"
        "area1      area2"
        "area3      area4";
}

.l_headerItme{
    grid-area: headerItme;
}

.l_area1{
    grid-area: area1;
}

.l_area2{
    grid-area: area2;
}

.l_area13{
    grid-area: area3;
}

.l_area4{
    grid-area: area4;
}

@media (min-width: 700px) {
    .l_WrokRequiredNotification_main{
        --l-width: 480px;
        --l-aspect-ratio: 1/1;
        --l-row-gap: 8px;
    }
}