.l_drag_and_drop_main{
    position: relative;

    min-height: 276px;

    box-sizing: border-box;

    z-index: 2;

    display: flex;
    align-items: center;
    justify-content: center;

    background-color: #F4F8F9;

    &.l_drag_and_drop__on_drag_enter{
        /* border: dashed 5px var(--g-color-mint); */
        color: var(--g-color-mint);
    }
}