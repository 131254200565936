.l_ProductBlock_infoContiner{
    width: 100%;
    min-height: 85px;

    display: flex;
    flex-direction: column;

    gap: 24px;
}

.l_ProductBlock_headerContainer{
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 14px;

    flex-wrap: wrap;
}

.l_ProductBlock_centerContainer{
    width: 100%;
    display: flex;
    flex-direction: column;

    gap: 8px;
}