.l_DeliveryAddressItem_main{
    --l-gap: 20px;

    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: var(--l-gap);
}

.l_DeliveryAddressItem_addressInfoContainer{
    display: flex;
    flex-direction: column;
    gap: 8px;
}

.l_DeliveryAddressItem_receiverInfoContainer{
    display: flex;
    align-items: center;
    gap: 14px;
}

.l_DeliveryAddressItem_buttonContainer{
    
}

@media (min-width: 700px) {
    .l_DeliveryAddressItem_main{
        --l-gap: 50px;
    }
}