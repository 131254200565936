.l_ModalChildrenContainer_main{
    --l-padding: 48px 40px 48px 40px;
    
    position: relative;

    width: auto;
    max-width: 95%;
    height: auto;
    max-height: 95%;

    display: flex;
    flex-direction: column;

    /* gap: 24px; */

    border-radius: 28px;
    overflow: hidden;

    background-color: white;

    padding: var(--l-padding);

    box-sizing: border-box;

    &.padding{
        &.none{
            --l-padding: 0px;
        }
    }

    &.min_heigth_full{
        height: 100%;
    }
}

.l_ModalChildrenContainer_childrenContainer{
    position: relative;
    width: 100%;
    height: 100%;

    overflow: auto;

    /* overflow-x방향으로 발생하는 불필요한 스크롤바를 제거한다. */
    /* overflow-y: auto; */
    /* overflow-x: hidden; */
}

.l_ModalChildrenContainer_childrenContainer:focus {
    outline: none;
  }

.l_ModalChildrenContainer_closeButton{
    position: absolute;
    top: 24px;
    right: 30px;

    z-index: 2;
}

.l_ModalChildrenContainer_headerContainer{
    width: 100%;
    height: 62px;
    padding: 24px 40px 14px 40px;
    border-bottom: 1px solid #E0E0E0;
}