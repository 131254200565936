.l_photo_detail__content_container{
    --l-content-container-margin-top: 0px;
    
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
    margin-top: var(--l-content-container-margin-top);
}

.l_left_wrap{
    display: flex;
    flex-direction: column;

    width: 100%;
    height: 0;
    flex-grow: 1;
}

.l_left_wrap__image_gallery_container{
    flex: 1;
    overflow: auto;

    display: flex;
    flex-direction: column;

    gap: var(--g-gap);
}

.l_image_name_container{
    display: flex;
    align-items: center;
    justify-content: center;
}

.l_photo_list_swipe_line_tooltip_container{
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: flex-start;
}

.l_photo_info_view_btn_container{
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: flex-end;

    .l_photo_info_view_btn_container__btn_cover{
        display: flex;
        align-items: center;
    }
}

.l_right_wrap{
    /* position: absolute;
    bottom: 0;
    left: 50%;
    transform: translate(-50%, 0); */

    width: 100%;
    height: auto;
    
    display: flex;
    flex-direction: column;
    align-items: center;

    box-sizing: border-box;

    overflow: hidden;

    &.open{
        height: auto;
        max-height: 100%;
        overflow: auto;
        z-index: 5;
    }
}

/* SD (854×480) */
@media (min-width: 700px) {
    .l_photo_detail__content_container{
        --l-content-container-margin-top: 19px;

        flex-direction: row;
        height: 0;
        flex-grow: 1;
        gap: 40px;
    }

    .l_left_wrap{
        height: 100%;
        width: 0;
        flex-grow: 1;
    }
    
    .l_left_wrap__image_gallery_container{
        padding: 15px;

        background-color: var(--g-color-main_contents-background);
    }

    .l_right_wrap{
        position: relative;

        width: 432px;
        height: 100%;
    
        padding-bottom: var(--g-content-margin-bottom);
    }
}