.l_theme_list_container_main{
    width: 100%;
    height: auto;
    min-height: 100%;

    display: flex;
    flex-direction: column;
    gap: var(--g-gap);
}

.l_theme_list__WideCard_container{
    width: 100%;
    height: 100%;
}