.l_DdayIcon_main{
    --l-color-dday-background: #F5F8F9;
    --l-color-dday-before: var(--g-color-primary);
    --l-color-dday-today: var(--g-color-primary);
    --l-color-dday-after: #AAAAAA;

    width: auto;

    display: flex;
    justify-content: center;
    align-items: center;

    border-radius: 30px;
    padding: 7px 16px 7px 16px;

    &.before{
        background-color: var(--l-color-dday-background);
        color: var(--l-color-dday-before);
    }

    &.dday{
        background-color: var(--l-color-dday-background);
        color: var(--l-color-dday-today);
    }

    &.after{
        background-color: var(--l-color-dday-background);
        color: var(--l-color-dday-after);
    }
}