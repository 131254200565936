.quill {
    width: 100%;
    height: 100%;
    min-height: 100%;

    display: flex;
    flex-direction: column;

    .ql-toolbar{
        position: sticky;
        top: 0;
        z-index: 1;
        background-color: white;
    }

    .ql-container{
        height: 0;
        flex-grow: 1;

        .ql-editor{
            height: 100%;
        }

        &.ql-snow{
            border: none;
        }
    }
    
}

/* 이미지 정렬을 위한 처리 */
img{
    &.ql-align-center {
        display: block;
        margin: 0 auto;
    }

    &.ql-align-right {
        display: block;
        margin-left: auto;
    }

    &.ql-align-left {
        display: block;
        margin-right: auto;
    }
}

/* SD (854×480) */
@media (min-width: 700px) {
    .quill {
        min-width: var(--g-textediter-width);
        max-width: var(--g-textediter-width);
    }

}