.l_Input_main{
    width: 100%;

    border: 1px solid #D5DDDE;
    border-radius: 14px;

    display: flex;
    flex-direction: row;

    padding: 15px 16px 14px 16px;

    overflow: hidden;
    box-sizing: border-box;

    background-color: white;

    gap: 16px;

    &.disabled{
        background-color: #F5F8F9;
    }
}

.l_Input_input{
    width: 100%;
    border: none;

    font-size: 16px;
    line-height: 19px;
    color: #414141;
}

.l_Input_input::placeholder{
    font-size: 16px;
    line-height: 19px;
    color: #AAAAAA;
}

.l_Input_input:disabled{
    background-color: #F5F8F9;
}

.l_Input_childrenContainer{
    display: flex;
    align-items: center;
    justify-content: center;
}