.l_EditRequestTextArea_textareaContainer{
    --l-height: 373px;

    width: 100%;
    height: var(--l-height);
}

@media screen {
    .l_EditRequestTextArea_textareaContainer{
        --l-height: 373px;
    }
}