.l_PaymentInfoItem_main{
    --l-grid-template-areas:
        "PayDate DescriptionIcon"
        "ProductName ."
        "SplitBar SplitBar"
        "ContractTotamount ContractTotamount"
        "ContractPayamount ContractPayamount"
        "ContractBalance ContractBalance"
    ;
    --l-grid-template-columns: 1fr auto;
    --l-border-radius: 20px 20px 0 0;
    --l-border: 1px solid #D5DDDE;
    --l-padding: 28px 24px 20px 24px;

    width: 100%;
    display: grid;
    grid-template-areas:var(--l-grid-template-areas);
    grid-template-columns: var(--l-grid-template-columns);

    border-radius: var(--l-border-radius);
    border: var(--l-border);

    padding: var(--l-padding);
    box-sizing: border-box;
}

.l_PayDate{
    grid-area: PayDate;
}

.l_ContractId{
    grid-area: ContractId;
}

.l_ProductName{
    grid-area: ProductName;
}

.l_DescriptionIcon{
    grid-area: DescriptionIcon;
}

.l_SplitBar{
    grid-area: SplitBar;
    margin: 20px 0 20px 0;
}

.l_ContractTotamount{
    --l-margin: 0 0 14px 0;

    margin: var(--l-margin);
    grid-area: ContractTotamount;
}

.l_ContractPayamount{
    --l-margin: 0 0 14px 0;

    margin: var(--l-margin);
    grid-area: ContractPayamount;
}

.l_ContractBalance{
    grid-area: ContractBalance;
}

.l_MorePayInfo{
    grid-area: MorePayInfo;
}

@media (min-width: 700px){
    .l_PaymentInfoItem_main{
        --l-grid-template-areas:
            "PayDate ContractId ProductName DescriptionIcon . ContractTotamount ContractPayamount ContractBalance MorePayInfo"
        ;
        --l-grid-template-columns: auto auto auto auto 1fr auto auto auto auto;
        --l-border-radius: none;
        --l-border: none;
        --l-padding: 24px 40px 24px 40px;

        align-items: center;

        border-bottom: 1px solid #D5DDDE;
    }

    .l_ContractTotamount{
        --l-margin: 0;
    }

    .l_ContractPayamount{
        --l-margin: 0;
    }
}