.l_custom_full_calendar_main{
    --l-gap: 16px;

    display: flex;
    flex-direction: column;
    position: relative;
    width: 100%;
    height: 100%;

    gap: var(--l-gap);
}

.l_custom_title {
    font-size: var(--g-font-size5) !important;
    color: var(--g-color-text-dark);
}

.l_fc_button_group{
    gap: 10px;
}

.l_fc_prev_button{
    background-color: white !important;
    border-radius: 100% !important;
    width: 43px;
    aspect-ratio: 1 / 1;
}

.l_fc_next_button{
    background-color: white !important;
    border-radius: 100% !important;
    width: 43px;
    aspect-ratio: 1 / 1;
}

.l_custom_full_calendar__calendar_container{
    --l-height: auto;
    --l-aspect-ratio: 4/3;

    width: 100%;
    height: var(--l-height);
    aspect-ratio: var(--l-aspect-ratio);
}

:global(.fc .fc-daygrid-day.fc-day-today){
    background-color: initial;
    border-radius: 100%;

    .fc-daygrid-day-frame{
        /* 일 영역 칸 */
        .fc-scrollgrid-sync-inner{
            
            .fc-daygrid-day-number{
                color: white;
            }
        }
    }
}

:global(.fc-daygrid-day-frame) {
    display: flex;
    justify-content: center;
    align-items: center;

    /* 일 영역 칸 */
    :global(.fc-scrollgrid-sync-inner){
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
    }
}


/* 일 숫자가 출력되는 영역 커스텀 */
:global(.fc-daygrid-day-number){
    display: flex;
    align-items: center;
    justify-content: center;
}

/* 이벤트 내용이 출력되던영역이 출력되지 않게함 */
:global(.fc-daygrid-day-events){
    display: none;
}

/* 요일부분 칸 스타일 커스텀 */
:global(.fc .fc-col-header-cell) {
    :global(.fc-scrollgrid-sync-inner){

        display: flex;
        align-items: center;
        justify-content: center;

        :global(.fc-col-header-cell-cushion){
            display: flex;
            align-items: center;
            justify-content: center;
        }
    }
}

/* :global(.fc.fc-daygrid-event) {
    display: block;
    width: 100%;
    height: 100%;
    text-align: center;
    font-size: 12px;
} */

/* 테이블로인행 생성되는 달력의 선 제거 */
:global(.fc-scrollgrid.fc-scrollgrid-liquid),
:global(.fc table),
:global(.fc td),
:global(.fc th) {
    border: none;
}

:global(.fc-scroller.fc-scroller-liquid-absolute){
    /* 강제적으로 스크롤바의 생성을 막는다. */
    overflow: hidden !important;
}

@media (min-width: 700px) {
    .l_custom_full_calendar_main{
        --l-gap: 14px;
    }

    .l_custom_full_calendar__calendar_container{
        --l-height: 0;
        --l-aspect-ratio: none;

        flex-grow: 1;
    }
}