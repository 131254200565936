.l_wide_card_main{
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: var(--g-color-components-background);

    &.background_color{
        &.white{
            background-color: white;
        }

        &.sky_blue{
            background-color: #f6fcff;
        }
    }

    &.cover_layer{
        position: absolute;
        background-color: rgba(0, 0, 0, 0.1);
    }
}