.l_ProductRequestEditMobileViewing_main{
    width: 100%;
    height: 100%;

    display: flex;
    flex-direction: column;

    gap: 8px;
}

.l_ProductRequestEditMobileViewing_contentContainer{
    width: 100%;
    height: 0;
    flex-grow: 1;
    overflow: auto;

    display: flex;
    flex-direction: column;
}

.l_ProductRequestEditMobileViewing_contentPage{
    width: 100%;
    height: auto;

    gap: 14px;

    display: flex;
    flex-direction: column;

    /* absolute속성의 SubmitBtnContainer에 의해 최 하단부의 컨텐츠가 가려지는것을 막기 위해 주는 공간 */
    margin-bottom: 110px;
}