.l_img_main{
    width: 100%;
    height: 100%;
    object-fit: contain; /* 비율 유지하며 컨테이너에 맞추기 */
    object-position: center; /* 이미지의 중심을 컨테이너의 중심으로 */

    &.object_fit{
        &.cover{
            object-fit: cover;
        }
    
        &.fill{
            object-fit: fill;
        }
    
        &.contain{
            object-fit: contain;
        }
    
        &.scale_down{
            object-fit: scale-down;
        }
    
        &.none{
            object-fit: none;
        }
    }


    &.height_standard{
        width: auto;
        height: 100%;
    }
}