.l_Datepicker_main{
    width: 100%;
    padding: 15px 16px 14px 16px;

    border: 1px solid #D5DDDE;
    border-radius: 14px;
    overflow: hidden;
    box-sizing: border-box;

    display: flex;
    flex-direction: row;
}

.l_Datepicker_header{
    margin: 10;
    display: flex;
    justify-content: center;
}

.l_Datepicker_childrenContainer{
    display: flex;
    align-items: center;
    justify-content: center;
}