.l_MonthlyScheduleCalendar_main{
    --l-gap: 30px;

    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;

    gap: var(--l-gap);
}

.l_MonthlyScheduleCalendar_calendarContainer{
    --l-height: auto;
    width: 100%;
}

.l_MonthlyScheduleCalendar_scheduleContainer{
    --l-gap: 14px;
    --l-padding: 20px;

    /* 일정이 하나일 때 2px정도 크기를더 키워서 컨테이너 높이를 생성해줘야 불필요한 오버플로우가 일어나지 않음 */
    /* 선택한 날짜의 일정칸(MonthlyScheduleListItem 컴포넌트) 크기의 변화가 생길경우 함께 조절해주는것이 좋다. */
    --l-height: auto;

    width: 100%;
    height: var(--l-height);

    border-radius: 20px;
    border: 1px solid #D5DDDE;

    padding: var(--l-padding);
    box-sizing: border-box;

    display: flex;
    flex-direction: column;

    gap: var(--l-gap);

    box-sizing: border-box;
}

.l_MonthlyScheduleCalendar_noScheduleTextContainer{
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}

@media (min-width: 700px) {
    .l_MonthlyScheduleCalendar_main{
        --l-gap: 19px;
    }

    .l_MonthlyScheduleCalendar_calendarContainer{
        height: 0;
        flex-grow: 1;
        aspect-ratio: none;
    }

    .l_MonthlyScheduleCalendar_scheduleContainer{
        --l-gap: 20px;
        --l-padding: 20px;
        --l-height: 98px;
        
        overflow: auto;
    }
}