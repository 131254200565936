.l_ExtensionItem_main{
    min-width: 160px;

    display: flex;
    align-items: center;
    justify-content: center;

    border-radius: 8px;

    padding: 10.5px;
}

.l_ExtensionItem_main:hover{
    background-color: #F5F8F9;
}