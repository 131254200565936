.l_AccountPage_main{
    --l-align-items: center;
    --l-padding: 0px 20px 0px 20px;

    width: 100%;
    height: 100%;

    display: flex;
    align-items: var(--l-align-items);
    justify-content: center;
    flex-direction: column;

    padding: var(--l-padding);
    box-sizing: border-box;

    background-color: #EAF1F3;

    gap: 24px;
}