.l_WrokRequiredItem_main{
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: space-between;
}

.l_WrokRequiredItem_titleText{
    --l-font-size: 16px;
    --l-line-height: 24px;

    font-size: var(--l-font-size);
    line-height: var(--l-line-height);
}

.l_WrokRequiredItem_numText{
    --l-font-size: 20px;
    --l-line-height: 30px;

    font-size: var(--l-font-size);
    line-height: var(--l-line-height);
}

@media (min-width: 700px) {
    .l_WrokRequiredItem_titleText{
        --l-font-size: 18px;
        --l-line-height: 27px;
    }
    
    .l_WrokRequiredItem_numText{
        --l-font-size: 24px;
        --l-line-height: 29px;
    }
}