.l_Text_main{
    --l-gap: 2px;

    display: flex;
    flex-direction: row;
    align-items: center;

    width: auto;
    max-width: 100%;

    gap: var(--l-gap);
}

.l_text{
    --l-text-color-dark: #131313;
    --l-text-color-gray0: #414141;
    --l-text-color-gray1: #717171;
    --l-text-color-gray2: #919191;
    --l-text-color-gray3: #AAAAAA;
    --l-text-color-gray4: #CCCCCC;
    --l-text-color-red_gray: #E66E76;
    --l-text-color-primary: var(--g-color-primary);
    --l-text-color-secondary: var(--g-color-secondary);

    --l-text-bold: 600;

    /* main_title */
    --l-font-size-menu-title: 20px;

    /* content_title */
    --l-font-size-content-title: 20px;

    /* LNB_menu */
    --l-font-size-LNB-menu: 20px;

    /* tab */
    --l-font-size-tab: 16px;

    /* main_content */
    --l-font-size-main-content: 16px;

    /* tag */
    --l-font-size-tag: 16px;

    /* button */
    --l-font-size-button: 16px;

    /* table_main_content */
    --l-font-size-table-main-content: 14px;

    /* table_tag */
    --l-font-size-table-tag: 14px;

    /* table_button */
    --l-font-size-table-button: 14px;

    /* navi */
    --l-font-size-navi: 20px;

    /* navi_badge */
    --l-font-size-navi-badge: 14px;

    /* detail_modal */
    --l-font-size-button-detail: 14px;

    /* select_theme_shoot_title */
    --l-font-select-theme-shoot-title: 18px;

    /* oval_icon_button */
    --l-font-oval_icon_button: 14px;

    /* history_back_button */
    --l-font-history_back_button: 18px;

    /* text_area_click_guide */
    --l-font-text-area-click-guide: 14px;

    /* swipe_text_item */
    --l-font-swipe-text-item: 16px;

    /* TitleBtnWrap_title */
    --l-font-titleBtnWrap-title: 18px;

    /* AccoutPageTitle */
    --l-font-accoutPageTitle: 30px;

    position: relative;

    width: auto;

    text-wrap: pretty;

    /* 폰트의 기본 크기는 main_content크기로 설정했다. */
    font-size: var(--l-font-size-main-content);
    color: var(--l-text-color-gray0);

    &.text_wrap{
        &.nowrap{
            text-wrap: nowrap;
        }

        &.wrap{
            text-wrap: wrap;
        }

        &.balance{
            text-wrap: balance;
        }

        &.pretty{
            text-wrap: pretty;
        }
    }

    &.size0{
        font-size: var(--g-font-size0);
    }
    
    &.size1{
        font-size: var(--g-font-size1);
    }
    
    &.size2{
        font-size: var(--g-font-size2);
    }
    
    &.size3{
        font-size: var(--g-font-size3);
    }
    
    &.size4{
        font-size: var(--g-font-size4);
    }
    
    &.size5{
        font-size: var(--g-font-size5);
    }
    
    &.size6{
        font-size: var(--g-font-size6);
    }
    
    &.size7{
        font-size: var(--g-font-size7);
    }
    
    &.bold{
        font-weight: var(--l-text-bold);
    }
    
    &.italic{
        font-style: italic;
    }

    &.is_title{
        font-size: var(--g-font-size7);
        color: var(--g-color-text-dark);
    }

    &.purpose{
        &.link{
            text-decoration-line: underline;
        }
    }

    &.text_decoration{
        &.underline{
            text-decoration: underline;
        }
    }

    &.text_truncation{
        text-wrap: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
    }

    &.preset{
        &.main_title{
            font-size: var(--l-font-size-menu-title);
            font-weight: var(--l-text-bold);
        }

        &.content_title{
            font-size: var(--l-font-size-content-title);
            font-weight: var(--l-text-bold);
        }

        &.LNB_menu{
            font-size: var(--l-font-size-LNB-menu);
        }

        &.tab{
            font-size: var(--l-font-size-tab);
        }

        /* 기본설정 */
        &.main_content{
            font-size: var(--l-font-size-main-content);
        }

        &.tag{
            font-size: var(--l-font-size-tag);
        }

        &.button{
            font-size: var(--l-font-size-button);
            font-weight: var(--l-text-bold);
        }

        &.table_main_content{
            font-size: var(--l-font-size-table-main-content);
        }

        &.table_tag{
            font-size: var(--l-font-size-table-tag);
        }

        &.table_button{
            font-size: var(--l-font-size-table-button);
        }

        &.navi{
            font-size: var(--l-font-size-navi);
        }

        &.navi_badge{
            font-size: var(--l-font-size-navi-badge);
            color: var(--l-text-color-red_gray);
            font-weight: var(--l-text-bold);
        }

        &.detail_modal{
            font-size: var(--l-font-size-button-detail);
            color: var(--l-text-color-secondary);
        }

        &.select_theme_shoot_title{
            font-size: var(--l-font-select-theme-shoot-title);
        }

        &.oval_icon_button{
            font-size: var(--l-font-oval_icon_button);
        }

        &.history_back_button{
            font-size: var(--l-font-history_back_button);
            color: var(--l-text-color-gray2);
        }

        &.text_area_click_guide{
            font-size: var(--l-font-text-area-click-guide);
            color: var(--l-text-color-gray1);
        }

        &.swipe_text_item{
            font-size: var(--l-font-swipe-text-item);
        }

        &.TitleBtnWrap_title{
            font-size: var(--l-font-titleBtnWrap-title);
        }

        &.AccoutPageTitle{
            font-size: var(--l-font-accoutPageTitle);
            font-weight: var(--l-text-bold);
            color: var(--l-text-color-dark);
        }
    }

    &.color{
        &.dark{
            color: var(--l-text-color-dark);
        }

        /* 기본 설정 */
        &.gray0{
            color: var(--l-text-color-gray0);
        }

        &.gray1{
            color: var(--l-text-color-gray1);
        }
        
        &.gray2{
            color: var(--l-text-color-gray2);
        }
        
        &.gray3{
            color: var(--l-text-color-gray3);
        }

        &.gray4{
            color: var(--l-text-color-gray4);
        }

        &.red_gray{
            color: var(--l-text-color-red_gray);
        }

        &.white{
            color: white;
        }

        &.primary{
            color: var(--l-text-color-primary);
        }

        &.secondary{
            color: var(--l-text-color-secondary);
        }
    }

    &.justify_content{
        &.start{
            justify-content: start;
        }

        &.center{
            justify-content: center;
        }

        &.end{
            justify-content: end;
        }
    }
}

.l_Text__essential_icon{
    margin-left: 0.3rem;
    color: var(--g-color-red-gray);
    flex-shrink: 0;
}

@media (min-width: 700px){
    .l_Text_main{
        --l-gap: 4px;
    }

    .l_text{
        /* main_title */
        --l-font-size-menu-title: 30px;

        /* content_title */
        --l-font-size-content-title: 24px;

        /* LNB_menu */
        --l-font-size-LNB-menu: 20px;

        /* tab */
        --l-font-size-tab: 20px;

        /* main_content */
        --l-font-size-main-content: 18px;

        /* tag */
        --l-font-size-tag: 16px;

        /* button */
        --l-font-size-button: 16px;

        /* table_main_content */
        --l-font-size-table-main-content: 16px;

        /* table_tag */
        --l-font-size-table-tag: 14px;

        /* table_button */
        --l-font-size-table-button: 14px;

        /* navi */
        --l-font-size-navi: 20px;

        /* navi_badge */
        --l-font-size-navi-badge: 14px;

        /* select_theme_shoot_title */
        --l-font-select-theme-shoot-title: 24px;

        /* text_area_click_guide */
        --l-font-text-area-click-guide: 14px;
    }
}