.l_ThreeSplitPages_main{
    --l-gap: 0px;

    width: 100%;
    height: 100%;
    overflow: auto;
    
    display: flex;
    flex-direction: row;

    gap: var(--l-gap);
}

.l_ThreeSplitPages_nodeContainer{
    position: relative;
    
    height: 100%;

    overflow: auto;

    &.left{
        width: auto;
    }

    &.center{
        width: 0;
        flex-grow: 1;
    }

    /* 모바일화면에선 가장 오른쪽영역만 사용되므로 정상적인 영역확보를 위해 100%로 설정 */
    &.right{
        width: 100%;
    }
}

@media (min-width: 700px) {
    .l_ThreeSplitPages_main{
        --l-gap: 24px;
    }

    .l_ThreeSplitPages_nodeContainer{    
        &.right{
            width: auto;
        }
    }
}