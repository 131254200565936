.l_PhotoInfoContainerSet_main{
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
}

.l_PhotoInfoContainerSet_container{
    width: 100%;
    height: auto;

    margin-bottom: 24px;
}

@media (min-width: 700px){
    .l_PhotoInfoContainerSet_main{
        height: 100%;
        margin-bottom: 24px;
    }

    .l_PhotoInfoPhotosTaken_container{
        width: 100%;
        height: auto;
        margin-bottom: 24px;
    }

    .l_webBtnContainer{
        width: 100%;
        height: 0;
        flex-grow: 1;

        display: flex;
        flex-direction: column;
        justify-content: space-between;
    }
}