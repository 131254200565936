.l_thumbnail_image_select_main{
    height: auto;
    width: 300px;

    flex-shrink: 0;

    gap: var(--g-gap-grid);

    display: flex;
    flex-direction: column;
}

.l_thumbnail_image_select__image_container{
    width: 100%;
    aspect-ratio: 1/1;

    position: relative;
}

.l_thumbnail_image_select__image_container__img{
    width: 100%;
    height: 100%;
}

.l_thumbnail_image_select__image_container__icon{
    position: absolute;

    width: 75px;
    height: 75px;

    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

.l_thumbnail_image_select__btn_container{
    display: flex;
    gap: var(--g-gap-wrap);
}