.l_AdditionalInfoContainer_main{
    --l-width: 100%;
    width: var(--l-width);
}

.l_AdditionalInfoContainer_inputComponents{
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 30px;
}

@media (min-width: 700px) {
    .l_AdditionalInfoContainer_main{
        --l-width: 432px;
    }
}