.l_IconX_main{
    width: 8px;
    height: 8px;
    display: flex;
    align-items: center;
    justify-content: center;

    &.preset{
        &.default{
            width: 8px;
            height: 8px;
        }

        &.big_black{
            width: 24px;
            height: 24px;
        }
    }
}

.l_IconX_iconImg{
    width: 100%;
    height: 100%;
    object-fit: contain;
}