.l_LoginInputContainer_main{
    width: 100%;
    display: flex;
    flex-direction: column;
    
    align-items: center;    
}

.l_LoginInputContainer_mainCardContainer{
    width: 100%;
    padding: 48px 24px 48px 24px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.l_LoginInputContainer_logoContainer{
    width: 120px;
    height: auto;
}

.l_LoginInputContainer_loginText{
    margin-top: 14px;
}

.l_LoginInputContainer_inputsContainer{
    margin-top: 34px;

    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 8px;
}

.l_LoginInputContainer_checkboxsContainer{
    margin-top: 16px;

    width: 100%;
    display: flex;
    flex-direction: row;
}

.l_LoginInputContainer_checkboxItemContainer{
    flex: 1;
}

.l_LoginInputContainer_loginBtn{
    margin-top: 24px;
}

.l_LoginInputContainer_Link{
    margin-top: 14px;
    text-decoration-color: var(--g-color-secondary);
}