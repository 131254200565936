.l_TabMenuItem_main{
    --l-color-active-border-line: var(--g-color-primary);

    --l-padding: 11px 6px 11px 6px;

    padding: var(--l-padding);

    box-sizing: border-box;

    &.active{
        border-bottom: 3px solid var(--l-color-active-border-line);
    }
}

@media (min-width: 700px){
    .l_TabMenuItem_main{
        --l-padding: 20px 14px 20px 14px; 
    }
}