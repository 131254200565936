.react-datepicker-popper{
    z-index: 2;
}

/* input의 크기를 직접적으로 제한하는 컨테이너 */
.react-datepicker-wrapper{
    width: 100%;

    display: flex;
    flex-direction: row;
    align-items: center;
}

.react-datepicker-ignore-onclickoutside{
    width: 100%;

    border: none;

    box-sizing: border-box;

    font-size: 16px;
    line-height: 19px;

    color: #414141;
}

.react-datepicker-ignore-onclickoutside::placeholder{
    font-size: 16px;
    line-height: 19px;
    color: #AAAAAA;
}