.l_ThemaDetailPageOverlay_main{
    position: relative;
    background-color: white;
    width: 100%;
    height: 100%;

    display: flex;
    flex-direction: column;
    justify-content: flex-start;
}

.l_ThemaDetailPageOverlay_titleContainer{
    position: sticky;
    top: 0;
    width: 100%;
    box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.05);
    z-index: 1;
}

.l_ThemaDetailPageOverlay_textEditerContainer{
    width: 100%;
    height: 100%;
}

.l_ThemaDetailPageOverlay_textEditer{
    border: none;
}