.l_Media_main{
    width: 100%;
    aspect-ratio: 4/3;
    overflow: hidden;

    &.aspect_ratio{
        &.aspect_ratio4_3{
            aspect-ratio: 4/3;
        }

        &.aspect_ratio1_1{
            aspect-ratio: 1/1;
        }
    }
}