.l_PaymentDetailItmeText_main{
    --l-justify-content: flex-start;

    width: 100%;
    display: flex;
    align-items: center;
    justify-content: var(--l-justify-content);
    box-sizing: border-box;

    &.justify_content{
        &.flex_start{
            --l-justify-content: flex-start;
        }

        &.flex_end{
            --l-justify-content: flex-end;
        }

        &.center{
            --l-justify-content: center;
        }
    }
}

@media (min-width: 700px){
    .l_PaymentDetailItmeText_main{
        --l-justify-content: flex-start;
        
        min-width: 150px;
    }
}