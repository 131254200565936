.l_AlertItem_main{
    width: 100%;

    display: flex;
    flex-direction: column;

    border-radius: 8px;
    padding: 16px 14px 16px 14px;

    box-sizing: border-box;

    &.new{
        background-color: #E6F3F4;
    }
}

.l_AlertItem_titleContainer{
    width: 100%;
}

.l_AlertItem_contentContainer{
    width: 100%;
}

.l_AlertItem_pushDateContainer{
    width: 100%;
    margin-top: 14px;
}


.l_AlertItem_text{
    width: 100%;
}

