.pswp__img {
    object-fit: contain !important;
    /* background-color: transparent !important; */
    background-color: black;
}

.pswp__content{
    display: flex;
    align-items: center;
    justify-content: center;
}