.l_TabMenu_main{
    width: 100%;
    height: auto;
    display: flex;
    flex-direction: row;

    /* MainPage내부에서 flex 특성으로 인해 찌그러지는것을 방지 */
    flex-shrink: 0;

    gap: 14px;

    overflow: auto;
}

@media (min-width: 700px){
    .l_TabMenu_main{
        border-bottom: 1px solid var(--g-color-line);
    }
}