.l_FolderImageOverlay_main{
    width: auto;

    /* 
        모달은 자식컴포넌트의 크기만큼 커지게 설계되어있다.
        하지만 여기서는 이미지의 크기를 특정할 수 없는 상태에서 이미지에 contain을 적용해야 한다.
        따라서 화면 전체크기에서 임의로 계선하여 진행한다.
        
        더 좋은 방법이 있으면 좋을텐데...
    */
    height: calc(var(--g-body-height) * 0.8);
}

.l_FolderImageOverlay_img{
    width: 100%;
    height: 100%;
}