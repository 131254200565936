.l_ThemeSelectInfoCard_main{
    width: 100%;
    height: auto;
}

.l_ThemeSelectInfoCard__contents_container{
    --l-contents-container-gap: 34px;

    width: 100%;
    display: flex;
    flex-direction: column;
    gap: var(--l-contents-container-gap);
}

@media (min-width: 700px) {
    .l_ThemeSelectInfoCard__contents_container{
        --l-contents-container-gap: 60px;
    }
}