.l_FolderEle_main{
    width: 100%;
    height: auto;
    padding: 15px 16px 15px 16px;
    border: 8px;

    display: flex;
    flex-direction: row;

    box-sizing: border-box;

    &.active{
        background-color: #F5F8F9;
    }
}

.l_FolderEle__icon_container{
    width: 24px;
    height: 24px;
    margin-left: auto;
}