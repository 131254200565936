.l_MovePageButtonIcon_main{
    --l-main-border-top: 4px;
    --l-main-border-bottom: 4px;
    --l-main-border-left: 6px;

    --l-main-color-border: var(--g-color-primary);


    border-top: var(--l-main-border-top) solid transparent;  /* 위쪽 테두리 투명 */
    border-bottom: var(--l-main-border-bottom) solid transparent; /* 아래쪽 테두리 투명 */
    border-left: var(--l-main-border-left) solid var(--l-main-color-border); /* 왼쪽 테두리에 색상 추가 (오른쪽을 향함) */
}

@media (min-width: 700px){
    .l_MovePageButtonIcon_main{
        --l-main-border-top: 6px;
        --l-main-border-bottom: 6px;
        --l-main-border-left: 9px;
    }
}