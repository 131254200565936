.l_MainButton_main{
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;

    padding: 14px 16px 14px 16px;

    border-radius: 8px;

    box-sizing: border-box;

    &.activate{
        background-color: white;
    }
}

.l_lable_container{
    height: auto;
    width: 0;
    flex-grow: 1;

    margin-left: 14px;

    display: flex;

    flex-direction: row;
    align-items: center;
}

.l_label{
    font-size: 16px;
    line-height: 24px;
}

.l_lable_badge{
    font-size: 14px;
    line-height: 21px;
    margin-left: 8px;
}

@media (min-width: 700px){
}