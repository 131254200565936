.l_header_main{
    --l-main-heigth: 44px;

    background-color: var(--g-color-header-background);

    width: 100%;
    height: var(--l-main-heigth);
    
    display: flex;
    align-items: center;

    padding-left: var(--g-header-padding-both-side);
    padding-right: var(--g-header-padding-both-side);

    &.background_color_preset{
        &.select_page{
            background-color: #EAF1F3;
        }
    }

    z-index: 5;

    box-sizing: border-box;
}

.l_header_left{
    flex: 1;
    gap: var(--g-gap);
    align-items: center;
}

.l_header_center{
    flex: 1;
    display: flex;
    justify-content: center;
    align-items: center;
}

.l_header_right{
    flex: 1;
    display: flex; 
    justify-content: flex-end;
    align-items: center;
    gap: 3px;
}

.l_header_logo{
    height: 24px;
}

.l_navi_btn{
    width: 100%;
    display: flex;
    align-items: center;
    gap: 15px;
}


/* SD (854×480) */
@media (min-width: 700px){
    .l_header_main{
        --l-main-heigth: 50px;

        &.mobile_background_color{
            &.select_page{
                background-color: initial;
            }
        } 
    }

    .l_header_left{    
        margin-left: 15px;
    }

    .l_header_right{
        gap: 22px;
    }
}