.l_DayCell_main{
    position: absolute;
}

.l_contentContainer{
    display: flex;
    flex-direction: column;
}

.l_DayCell{
    position: relative;

    display: flex;
    align-items: center;
    justify-content: center;
    width: 40px;
    aspect-ratio: 1 / 1;
    border-radius: 100%;
}

.l_DayCell_dotContainer{
    position: absolute;

    left: 50%;
    bottom: 3px;
    transform: translateX(-50%);

    display: flex;
    justify-content: center;
    
    gap: 4px;

    z-index: 1;
}

.l_DayCell_dot{
    --l-background-color: var(--g-color-primary);

    width: 7px;
    height: 7px;
    border-radius: 100px;
    background-color: var(--l-background-color);

    &.holiday{
        --l-background-color: var(--g-color-red-gray);
    }
}

.l_today{
    border: 1px solid var(--g-color-primary);
}

.l_selected{
    background-color: var(--g-color-primary);
}

.l_event{
    /* background-color: var(--g-color-primary); */
}

@media (min-width: 700px) {
}