.l_Navi_main{
    width: 100%;
    height: 100%;

    background-color: var(--g-color-navi-background);

    position: absolute;
    z-index: 5;

    display: flex;
    flex-direction: column;

    padding: 10px 20px 0 20px;

    box-sizing: border-box;

    overflow: auto;
    
    &.navi_close{
        display: none;
    }
}

.l_closeBtnContainer{
    width: 100%;
    display: flex;
    justify-content: end;
}

.l_Navi__NaviFunctionBtn_container{
    position: absolute;
    width: 100%;
    height: 50px;
    display: flex;
    align-items: center;
}

.l_Navi__btn_container{
    width: 100%;
    display: flex;
    flex-direction: column;

    gap: 10px;
}

.l_Navi__logo_container{
    width: 120px;
    height: auto;

    margin-top: 60px;
    margin-bottom: 85px;
    margin-left: auto;
    margin-right: auto;

    display: flex;
    align-items: center;
    justify-content: center;
}

/* SD (854×480) */
@media (min-width: 700px) {
    .l_Navi_main{
        position: relative;

        width: 353px;

        &.navi_close{
            display: flex;
        }
    }
}