.l_ProductDetailPageMobileViewing_main{
    position: relative;

    width: 100%;
    height: 0;
    flex-grow: 1;
    padding: 0 0 60px 0;
    overflow: auto;
}

.l_ProductDetailPageMobileViewing_buttonContainer{
    position: sticky;
    left: 0;
    bottom: 60px;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 20px;

    z-index: 3;
}