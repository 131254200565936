.l_UploadImageContainer_main{
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    box-sizing: border-box;

    gap: 20px;
}

.l_UploadImageContainer_header{
    width: 100%;
    display: flex;
    align-items: flex-end;
    flex-direction: row;
    justify-content: space-between;
    flex-wrap: wrap;
    gap: var(--g-gap-grid);

    background-color: white;

    z-index: 3;
}

.l_UploadImageContainer_header_left{
    display: flex;
    flex-direction: row;
    align-items: center;
    flex-wrap: wrap;
    gap: 15px;
}

.l_UploadImageContainer_header_right{
    display: flex;
    flex-direction: row;
    align-items: center;
    flex-wrap: wrap;
    gap: 4px;
}