.l_body {
    display: flex;
    flex-direction: row;
    width: 100vw;
    height: 100dvh;

    overflow: hidden;
}

.l_container {
    width: 0;
    flex-grow: 1;
    height: 100%;
    display: flex;
    flex-direction: column;
    position: relative;
}

.l_main_contents {
    width: 100%;
    height: 0;
    flex-grow: 1;
    
    position: relative;

    -webkit-overflow-scrolling: touch;
    
    display: flex;

    box-sizing: border-box;

    overflow: auto;
}