.l_EditRequestView_TextareaContainer{
    --l-hight: 367px;

    width: 100%;
    height: var(--l-hight);
}

@media (min-width: 700px){
    .l_EditRequestView_TextareaContainer{
        --l-hight: 373px;
    }
}