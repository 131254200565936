.l_SelectThemeController_main{
    --l-content-container-gap: 40px;
    --l-main-width: 100%;

    position: relative;

    width: var(--l-main-width);
    height: 100%;
    display: flex;
    flex-direction: column;

    gap: var(--l-content-container-gap);
}

@media (min-width: 700px){
    .l_SelectThemeController_main{
        --l-content-container-gap: 0;
        --l-main-width: 432px;
    }
}