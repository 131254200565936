.l_Postcode_main{
    width: 100%;
    background-color: white;
}

.l_Postcode_inputContainer{
    width: 100%;
    display: grid;
    grid-template-areas:
        "PostNumer ."
        "Address Address"
        "DetailAddress DetailAddress"
    ;
    grid-template-columns: 1fr 1fr;
    row-gap: 8px;
    column-gap: 8px;
}

.l_Postcode_inputEleContainer{
    width: 100%;

    &.PostNumer{
        grid-area: PostNumer;
    }

    &.Address{
        grid-area: Address;
    }

    &.DetailAddress{
        grid-area: DetailAddress;
    }
}

.l_Postcode_DaumPostcodeEmbedOverlay{
    --l-Postcode-DaumPostcodeEmbedOverlay-width: 100%;
    width: var(--l-Postcode-DaumPostcodeEmbedOverlay-width);
}

@media (min-width: 700px) {
    .l_Postcode_DaumPostcodeEmbedOverlay{
        --l-Postcode-DaumPostcodeEmbedOverlay-width: 540px;
    }
}