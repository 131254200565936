.l_UploadImageItemEle_main{
    --l-main: 100%;
    
    position: relative;
    
    width: var(--l-main);
    height: auto;

    display: flex;
    flex-direction: column;
    align-items: center;

    gap: 8px;
}

.l_UploadImageItemEle_WasteBasketIcon{
    --l-WasteBasketIcon-top: 6px;
    --l-WasteBasketIcon-right: 6px;
    
    position: absolute;
    top: var(--l-WasteBasketIcon-top);
    right: var(--l-WasteBasketIcon-right);
}

@media (min-width: 700px) {
    .l_UploadImageItemEle_main{
        /* 5픽셀 차이는 어디서 나오는걸까???? */
        --l-main: calc(calc((100% - (var(--g-flex-gap-select-product-uploaded-image-list) * 2)) / 3) - 5px);
        max-width: 304px;
    }

    .l_UploadImageItemEle_WasteBasketIcon{
        --l-WasteBasketIcon-top: -7px;
        --l-WasteBasketIcon-right: -7px;
    }
}