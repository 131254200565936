.l_LabelAndContent_main{
    --l-flex-direction: row;
    --l-main-gap: 14px;
    --l-align-items: flex-start;
    
    width: 100%;
    display: flex;
    align-items: var(--l-align-items);
    flex-direction: var(--l-flex-direction);

    gap: var(--l-main-gap);

    &.flex_direction{
        &.row{
            --l-flex-direction: row;
        }

        &.column{
            --l-flex-direction: column;;
            --l-align-items: flex-start;
        }
    }
}

.l_LabelAndContent_headerContainer{
    display: flex;
    align-items: center;
    justify-content: space-between;

    &.use_header_node{
        width: 100%;
    }
}

.l_LabelAndContent_headerNode{
    margin-left: auto;

    display: flex;
    align-items: center;
    gap: 4px;
}

.l_LabelAndContent_contentContianer{
    width: 0;
    flex-grow: 1;
    display: flex;
    flex-direction: row;
    align-items: center;

    gap: 8px;
}

.l_LabelAndContent_content{
    width: 100%;
}

@media (min-width: 700px) {
    .l_LabelAndContent_main{
        --l-main-gap: 24px;

        gap: var(--l-main-gap);

        &.flex_direction{
            &.row{
                flex-direction: row;
            }
    
            &.column{
                flex-direction: column;
                --l-main-gap: 14px;
            }
        }
    }
}