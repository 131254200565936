.l_image_viewer_main{
    position: relative;

    width: 100%;
    height: 100%;

    &.is_use_icon{
        margin: 3px;
        padding: 2px;
        width: 30px;
        height: 30px;

        display: flex;
        align-items: center;
        justify-content: space-between;

        border-radius: 100%;

        background-color: gray;
    }
}

.l_image_viewer__img{
    width: 100%;
    height: 100%;
}

.l_image_viewer__zoom_img{
    position: absolute;
    z-index: 1;

    bottom: 8px;
    right: 6px;

    /* absolute스타일에서도 이벤트가 상위컴포넌트로 전파되게 하기위해 */
    pointer-events: none;
}