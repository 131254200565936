.l_Copy_main{
    --l-width: 16px;
    --l-height: 16px;
    width: var(--l-width);
    height: var(--l-height);

    /* Text컴포넌트 내부에서 사용할 때 구조상 찌그러져 사라지는것을 방지 */
    flex-shrink: 0;
}

.l_Copy_icon{
    width: 100%;
    height: 100%;
    object-fit: contain;
}

@media (min-width: 700px) {
    .l_Copy_main{
        --l-width: 18px;
        --l-height: 18px;
    }
}