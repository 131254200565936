.l_PaymentHistoryListHeader_main{
    background-color: #F5F8F9;
    
    width: 100%;
    padding: 15px 40px 14px 40px;

    display: grid;
    grid-template-areas:
        "PayDate ContractId ProductName DescriptionIcon . ContractTotamount ContractPayamount ContractBalance MorePayInfo"
    ;
    grid-template-columns: auto auto auto auto 1fr auto auto auto auto;

    border-radius: 8px;

    box-sizing: border-box;
}

.l_PayDate{
    grid-area: PayDate;
}

.l_ContractId{
    grid-area: ContractId;
}

.l_ProductName{
    grid-area: ProductName;
}

.l_DescriptionIcon{
    grid-area: DescriptionIcon;
}

.l_ContractTotamount{
    grid-area: ContractTotamount;
}

.l_ContractBalance{
    grid-area: ContractBalance;
}

.l_ContractPayamount{
    grid-area: ContractPayamount;
}

.l_MorePayInfo{
    grid-area: MorePayInfo;
}