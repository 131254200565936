.l_text_area_main{
    --l-font-size: 14px;
    --l-line-height: 21px;
    --l-text-color: #414141;

    resize: none;
    border-radius: 14px;
    border: 1px solid #D5DDDE;

    width: 100%;
    height: 100%;
    
    resize: none;
    box-sizing: border-box;

    padding: 18px 16px 18px 16px;
    box-sizing: border-box;

    color: var(--l-text-color);
    font-size: var(--l-font-size);
    line-height: var(--l-line-height);

    &.disabled{
        --l-text-color: #919191;
    }
}

.l_text_area_main::placeholder{
    color: #AAAAAA;
    font-size: 16px;
    line-height: 24px;
}

@media (min-width: 700px){
    .l_text_area_main{
        --l-font-size: 16px;
        --l-line-height: 24px;
    }
}