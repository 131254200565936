.l_ContractAmountDetailPart_main{
    --l-justify-content: space-between; 

    display: flex;
    align-items: center;
    justify-content: var(--l-justify-content);

    &.justify_content{
        &.flex_start{
            --l-justify-content: flex-start;
        }

        &.flex_end{
            --l-justify-content: flex-end;
        }

        &.center{
            --l-justify-content: center;
        }

        &.space_between{
            --l-justify-content: space-between;
        }
    }
}

@media (min-width: 700px) {
    .l_ContractAmountDetailPart_main{
        --l-justify-content: flex-end;
        
        min-width: 140px;
    }
}