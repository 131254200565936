.l_CommonIcon_main{
    width: 26px;
    height: 26px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.l_CommonIcon_img{
    width: 100%;
    height: 100%;
    object-fit: contain;
}