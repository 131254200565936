.l_ContractDate_main{
    --l-align-items: flex-start;

    /* width: 100%; */
    display: flex;
    align-items: var(--l-align-items);
}

@media (min-width: 700px) {
    .l_ContractDate_main{
        --l-align-items: center;

        min-width: 160px;
    }
}