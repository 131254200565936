.l_function_icon_main{
    width: 40px;
    height: 40px;

    display: flex;
    align-items: center;
    justify-content: center;

    border-radius: 100%;
    background-color: rgba(0, 0, 0, 0.4);

    .l_function_icon__img{
        width: 24px;
        height: 24px;
        object-fit: contain;
    }
}