.l_HoverScreen_main{
    position: absolute;
    top: 0;
    left: 0;

    width: 100%;
    height: 100%;

    border-radius: 20px;

    background-color: rgba(0, 0, 0, 0);

    transition: background-color 0.3s ease-in-out;
}

.l_HoverScreen_main:hover{
    background-color: rgba(0, 0, 0, 0.3);
}

.l_HoverScreen_icon_container{
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    
    width: auto;
    height: auto;

    display: none;
}

.l_HoverScreen_main:hover .l_HoverScreen_icon_container{
    display: block;
}