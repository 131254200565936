.l_image_gallery_toolbar_btn_main{
    width: 50px;
    height: 50px;
    display: flex;
    align-items: center;
    justify-content: center;

    border-radius: 50%;

    .l_image_gallery_toolbar_btn__icon_cover{
        width: 50%;
        height: 50%;
        transition: width 0.1s ease, height 0.1s ease;

        &:hover{
            width: 70%;
            height: 70%;
        }

        .l_image_gallery_toolbar_btn__icon_cover__icon{
            width: 100%;
            height: 100%;
            object-fit: contain;
        }
    }   
}