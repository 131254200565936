.l_DescriptionIcon_main{
    --l-width: 30px;
    --l-heigth: 30px;

    width: var(--l-width);
    height: var(--l-heigth);

    display: flex;
    align-items: center;
    justify-content: center;
    
    &.size{
        &.small{
            --l-width: 20px;
            --l-heigth: 20px;
        }

        &.medium{
            --l-width: 30px;
            --l-heigth: 30px;
        }
    }
}

.l_Img{
    width: 100%;
    height: 100%;
    object-fit: contain;
}