.l_MoveArrowBtn_main{
    --l-bottom: 60px;

    position: absolute;
    left: var(--l-left);
    right: var(--l-right);
    bottom: var(--l-bottom);
    z-index: 5;

    &.diraction{
        &.left{
            left: 30px;
        }

        &.right{
            right: 30px;
        }
    }
}

@media (min-width: 700px) {
    .l_MoveArrowBtn_main{
        --l-bottom: 40px;

        &.diraction{
            &.left{
                left: 48px;
            }
    
            &.right{
                right: 48px;
            }
        }
    }
}