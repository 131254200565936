.l_EditIcon_main{
    width: 26px;
    height: 26px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.l_EditIcon_iconImg{
    width: 100%;
    height: 100%;
    object-fit: contain;
}