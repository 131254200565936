.l_AddressInfoCard_main{
    --l-width: 100%;
    width: var(--l-width);
}

.l_recipientInfoContainer{
    width: 100%;
    display: grid;
    grid-template-areas:
        "recipientName    recipientPhonenum"
        "recipientAddress recipientAddress"
    ;
    grid-template-columns: 1fr 1fr;
    row-gap: 8px;
    column-gap: 8px;
}

.l_recipientName{
    grid-area: recipientName;
}

.l_recipientPhonenum{
    grid-area: recipientPhonenum;
}

.l_recipientAddress{
    grid-area: recipientAddress;
}

@media (min-width: 700px) {
    .l_AddressInfoCard_main{
        --l-width: 432px;
    }
}