.l_PageChangeTabMenu_main{
    width: 100%;
    padding: 5px 6px 5px 6px;
    border-radius: 20px;
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    background-color: white;
    box-sizing: border-box;
}

/* 여기서부턴 버튼 스타일 */
.l_PageChangeTabButton_main{
    border-radius: 16px;
    /* 본래 다자인대로 padding처리시 화면 크기에따라 버튼이 화면을 넘어가는 경우가 생기므로 임의로 사이즈롤 작게 조절 */
    padding: 7px 20px 7px 20px;
    box-sizing: border-box;

    &.highlight{
        background-color: var(--g-color-primary-10);
    }
}