.l_WrokRequiredMainItem_main{
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    /* align-items: center; */
    justify-content: center;
    box-sizing: border-box;
}

.l_WrokRequiredMainItem_textLineContainer{
    display: flex;
    flex-direction: row;

    gap: 0.3rem;
}

.l_WrokRequiredMainItem_text{
    --l-font-size: 18px;
    --l-line-height: 27px;

    font-size: var(--l-font-size);
    line-height: var(--l-line-height);
}

.l_WrokRequiredMainItem_highlightsText{
    --l-font-size: 20px;
    --l-line-height: 30px;

    font-size: var(--l-font-size);
    line-height: var(--l-line-height);
}

@media (min-width: 700px){
    .l_WrokRequiredMainItem_text{
        --l-font-size: 20px;
        --l-line-height: 36px;
    }

    .l_WrokRequiredMainItem_highlightsText{
        --l-font-size: 24px;
        --l-line-height: 36px;
    }
}