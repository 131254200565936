.l_download_loading_modal_text_container{
    width: 100%;
    height: auto;

    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 10px;

    padding: 30px;
    box-sizing: border-box;
    
    .l_download_loading_modal_text_container__text{
        overflow-wrap: break-word;
    }
}
