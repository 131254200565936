.l_SwipeTextItem_main{
    display: flex;
    align-items: center;
    text-wrap: nowrap;

    padding: 12px 16px 12px 16px;
    border-radius: 12px;
    background-color: #F5F8F9;

    &.highlight{
        background-color: var(--g-color-primary-10);
    }
}