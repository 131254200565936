.l_ThemeBookHome_filterContainer{
    width: 100%;

    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    gap: 14px;
}

@media (min-width: 700px) {
    .l_ThemeBookHome_filterContainer{
        padding: 20px 35px 20px 35px;
        border-radius: 30px;
        border: 1px solid #D5DDDE;
        box-sizing: border-box;
    }
}