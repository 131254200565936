.l_PaymentDetailHeader_main{
    --l-grid-template-areas: 
        "PayDate PayType PayMethod PayAmount"
    ;
    --l-grid-template-columns: auto auto auto 1fr;

    width: 100%;
    display: grid;
    grid-template-areas: var(--l-grid-template-areas);
    grid-template-columns: var(--l-grid-template-columns);
}

/* 결제일 */
.l_PayDate{
    grid-area: PayDate;
}

/* 결제 종류 */
.l_PayType{
    grid-area: PayType;
}

/* 결제 수단 */
.l_PayMethod{
    grid-area: PayMethod;
}

/* 결제 금액 */
.l_PayAmount{
    grid-area: PayAmount;
}