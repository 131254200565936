.l_ShootListItem_main{
    width: 100%;
    padding: 15px 18px 15px 18px;
    border-radius: 8px;

    box-sizing: border-box;

    &.highlight{
        background-color: #F5F8F9;
    }
}