.l_ProductInfoCardViewMode_main{
    --l-width: 100;

    width: var(--l-width);
    height: auto; 
}

@media (min-width: 700px){
    .l_ProductInfoCardViewMode_main{
        --l-width: 432px;
    }
}