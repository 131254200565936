.l_SelectedThemeListContainer_main{
    --l-main-gap: 24px;
    
    width: 100%;
    height: auto;
    display: flex;
    flex-direction: column;
    gap: var(--l-main-gap);
}

@media (min-width: 700px){
    .l_SelectedThemeListContainer_main{
        --l-main-gap: 40px;
    }
}