.l_SearchIcon_main{
    width: 20px;
    height: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.l_SearchIcon_iconImg{
    width: 100%;
    height: 100%;
    object-fit: contain;
}