.l_WrokRequiredItemContainer_main{
    --l-border-radius: 12px;
    --l-padding: 20px;

    /* 크기는 따로 설정하지 않고, l_WrokRequiredNotification_main컴포넌트의 Grid를 통해 컨트롤합니다. */

    border-radius: var(--l-border-radius);
    padding: var(--l-padding);

    &.preset{
        &.item{
            border: 1px solid #D5DDDE;
            background-color: white;
        }

        &.main_item{
            background-color: #F5F8F9;
        }
    }
}

@media (min-width: 700px){
    .l_WrokRequiredItemContainer_main{
        --l-border-radius: 24px;

        &.preset{
            &.item{
                --l-padding: 24px 20px 24px 20px;
            }
        }
    }
}