.l_PhotoSwipeGalleryItem_MediaItemCardContainer{
    width: calc((100% - var(--g-flex-gap-select-product-uploaded-image-list)) / 2);

    &.preset{
        &.ReactPhotoSwipeSingleViewer{
            width: 100%;
            height: 100%;
        }
    }
}

@media (min-width: 700px){
    .l_PhotoSwipeGalleryItem_MediaItemCardContainer{
        /* ReactPhotoSwipeGalleryContents내부에서 사용될 경우 flex로 처리되므로 가로길이를 100%으로 주지 않고 자동으로 처리시킨다. */
        width: auto;
    }
}