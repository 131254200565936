.l_ChangePassword_main{
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 24px;
}

.l_ChangePassword_passwordContainer{
    width: 100%;
}

.l_ChangePassword_newPasswordContainer{
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 18px; 
}

.l_ChangePassword_buttonContainer{
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 8px;
}