.l_Checkbox_main{
    position: relative;

    width: 16px;
    height: 16px;
    border: 1px solid #D5DDDE;
    border-radius: 4px;

    background-color: white;
}

.l_Checkbox_markIconContainer{
    position: absolute;

    width: 100%;
    height: 100%;
    background-color: var(--g-color-primary);

    display: flex;
    align-items: center;
    justify-content: center;
}

.l_Checkbox_markIconImg{
    width: 100%;
    height: 100%;
    object-fit: contain;
}