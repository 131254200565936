.l_AlertOverlay_main{
    --l-position: fixed;
    --l-width: 100%;
    --l-max-height: 100%;
    --l-top: 0;

    display: flex;
    flex-direction: column;

    position: var(--l-position);
    top: var(--l-top);
    right: 0;

    width: var(--l-width);
    height: auto;
    max-height: var(--l-max-height);

    background-color: white;

    z-index: 5;

    padding: 28px 14px 28px 14px;
    box-sizing: border-box;
}

.l_AlertOverlay_titleContainer{
    --l-justify-content: center;

    position: relative;
    top: 0;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: var(--l-justify-content);
}

.l_AlertOverlay_closeBtn{
    --l-display: flex;

    display: var(--l-display);
    position: absolute;
    top: 0;
    right: 0;
}

.l_AlertOverlay_listContainer{
    overflow: auto;
}

@media (min-width: 700px) {
    .l_AlertOverlay_main{
        --l-position: absolute;
        --l-width: 484px;
        --l-max-height: 700px;
        --l-top: 100%;

        border-radius: 12px;
        border: 1px solid #D5DDDE;
    }

    .l_AlertOverlay_titleContainer{
        --l-justify-content: flex-start;
    }

    .l_AlertOverlay_title{
        margin-left: 10px;
    }

    .l_AlertOverlay_closeBtn{
        --l-display: none;
    }
}