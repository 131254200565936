.l_BottomSheetControlBar_main{
    width: 100%;
    height: 74px;
    display: flex;
    justify-content: center;

    /* 바텀시트 내부에서 flex로 인해 찌그러지지 않도록 처리 */
    flex-shrink: 0;
}

.l_BottomSheetControlBar__slide_bar{
    width: 140px;
    height: 6px;
    border-radius: 3px;
    background-color: #AAAAAA;
}