.l_modal{
    position: fixed;
    width: 100%;
    height: 100%;

    top: 50%; /* 수직 중앙 고정 */
    left: 50%; /* 수평 중앙 고정 */
    transform: translate(-50%, -50%); /* 모달을 화면 중앙에 정확히 배치 */
    z-index: 10;
    
    display: flex;
    justify-content: center;
    align-items: center;

    overflow: hidden;

    background-color: rgba(0, 0, 0, 0.5);
}

.l_modal__children_container{
    position: relative;
    width: 90%;
    height: auto;
    max-width: 90%;
    max-height: 90%;

    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

@media(min-width: 700px){
    .l_modal{
        padding-top: var(--g-main-contents-padding-top);
        padding-bottom: var(--g-main-contents-padding-bottom);
        padding-left: var(--g-header-padding-both-side);
        padding-right: var(--g-header-padding-both-side);
    }

    .l_modal__children_container{
        width: auto;
        height: auto;
    }

    .l_modal__children_container__children{
        width: 100%;
    }
}