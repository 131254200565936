.loader {
    border: 16px solid rgba(0, 0, 0, 0);
    border-radius: 50%;
    border-top: 16px solid var(--g-color-mint);
    width: 120px;
    aspect-ratio: 1 / 1;
    animation: spin 0.5s linear infinite;
}
  
@keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
}