.l_ArrowIcon_main{
    width: 68px;
    height: 68px;

    background-color: white;

    border-radius: 1000px;
    border: 1px solid #D5DDDE;
    box-shadow: 0px 3px 20px #0000000D;

    padding: 10px;
    box-sizing: border-box;
}

.l_img{
    width: 100%;
    height: 100%;
    object-fit: contain;
}