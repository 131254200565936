.l_SubmitBtnContainer_main{
    --l-gap: 8px;
    --l-padding: 0 14px 40px 14px;

    position: absolute;
    left: 0;
    bottom: 0;

    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    gap: var(--l-gap);
    padding: var(--l-padding);
    box-sizing: border-box;

    z-index: 3;
}

@media (min-width: 700px) {
    .l_SubmitBtnContainer_main{
        --l-gap: 14px;
        --l-padding: 14px 14px 45px 14px;
        
        position: sticky;
    }
}