.l_WasteBasket_main{
    --l-width: 24px;
    --l-height: 24px;

    width: var(--l-width);
    height: var(--l-height);

    border-radius: 300px;

    background-color: var(--g-color-red-gray);

    position: relative;
}

.l_WasteBasket_icon{
    --l-width-icon: 17px;
    --l-height-icon: 17px;

    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);

    width: var(--l-width-icon);
    height: var(--l-height-icon);

    object-fit: contain;
}

@media (min-width: 700px) {
    .l_WasteBasket_main{
        --l-width: 32px;
        --l-height: 32px;
    }

    .l_WasteBasket_icon{
        --l-width-icon: 20px;
        --l-height-icon: 20px;
    }
}