.l_ThemeSelectButtons_main{
    --l-main-gap: 8px;
    --l-padding: 0 0 40px 0;

    position: sticky;
    left: 0;
    bottom: 0;

    width: 100%;
    height: auto;
    
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;    
    gap: var(--l-main-gap);

    padding: var(--l-padding);
}

@media (min-width: 700px){
    .l_ThemeSelectButtons_main{
        --l-main-gap: 14px;
        --l-padding: 24px 0 45px 0;
    }
}