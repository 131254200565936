.l_EmptyData_main{
    width: 100%;
    height: auto;
    display: flex;
    align-items: center;
    justify-content: center;
}

.l_EmptyData_contentContainer{
    margin-top: 103px;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 24px;
}

.l_EmptyData_logoContainer{
    width: 140px;
    height: 42.4px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.l_EmptyData_logoImg{
    width: 100%;
    height: 100%;
    object-fit: contain;
}