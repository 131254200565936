.l_Login_main{
    --l-width: 100%;

    width: var(--l-width);
    height: auto;

}

@media (min-width: 700px) {
    .l_Login_main{
        --l-width: 432px;
    }
}