.l_ThemeItemCard_main{
    /* --g-filtedSubThemeListContainer-gap-x는 ThemeBook의 css파일 내에서 선언되었다. */
    --l-width: calc((100% - var(--g-filtedSubThemeListContainer-gap-x)) / 2);
    --l-gap: 14px;
    --l-padding: 14px 10px 14px 10px;

    width: var(--l-width);
    height: auto;

    display: flex;
    flex-direction: column;
    gap: var(--l-gap);

    padding: var(--l-padding);

    overflow: hidden;
}

.l_ThemeItemCard_thumbnailContainer{
    --l-thumbnailContainer-border-radius: 8px;

    width: 100%;
    height: auto;
    aspect-ratio: 1/1;
    border-radius: var(--l-thumbnailContainer-border-radius);
}

.l_ThemeItemCard_infoContainer{
    display: flex;
    flex-direction: column;
}

.l_ThemeItemCard_photoProductName{
    --l-photoProductName-font-size: 14px;
    --l-photoProductName-line-height: 21px;

    font-size: var(--l-photoProductName-font-size);
    line-height: var(--l-photoProductName-line-height);
}

.l_ThemeItemCard_themeName{
    --l-themeName-font-size: 18px;
    --l-themeName-line-height: 27px;
    --l-themeName-margin-top: 6px;

    font-size: var(--l-themeName-font-size);
    line-height: var(--l-themeName-line-height);
    margin-top: var(--l-themeName-margin-top);
}

.l_ThemeItemCard_officeName{
    --l-officeName-font-size: 13px;
    --l-officeName-line-height: 24px;
    --l-officeName-margin-top: 16px;

    font-size: var(--l-officeName-font-size);
    line-height: var(--l-officeName-line-height);
    margin-top: var(--l-officeName-margin-top);
}

.l_emptyContentLayer{
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    background-color: rgba(128, 128, 128, 0.5);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: var(--g-gap-wrap);
}

.l_emptyContentLayer_text{
    color: white;
}

@media (min-width: 700px){
    .l_ThemeItemCard_main{
        --l-width: calc((100% - (var(--g-filtedSubThemeListContainer-gap-x) * 3)) / 4);
        --l-gap: 20px;
        --l-padding: 24px 20px 24px 20px;
        max-width: 360px;
    }

    .l_ThemeItemCard_thumbnailContainer{
        --l-thumbnailContainer-border-radius: 20px;
    }

    .l_ThemeItemCard_infoContainer{
        padding: 0 10px 0 10px;
    }

    .l_ThemeItemCard_photoProductName{
        --l-photoProductName-font-size: 18px;
        --l-photoProductName-line-height: 27px;
    }

    .l_ThemeItemCard_themeName{
        --l-themeName-font-size: 24px;
        --l-themeName-line-height: 36px;
        --l-themeName-margin-top: 8px;
    }

    .l_ThemeItemCard_officeName{
        --l-officeName-font-size: 16px;
        --l-officeName-line-height: 24px;
        --l-officeName-margin-top: 34px;
    }
}