.l_download_btn_container_main{
    position: sticky;
    bottom: 0;

    width: 100%;
    height: auto;

    display: flex;
    flex-direction: column;
    align-items: center;

    gap: 14px;

    background-color: var(--g-color-main_contents-background);
}