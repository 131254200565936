.l_MainInfoCard_main{
    width: 100%;
    height: auto;

    display: flex;
    flex-direction: column;
    align-items: flex-start;
}

.l_MainInfoCard__header_title_container{
    width: 100%;
    display: flex;
    flex-direction: column;
}

.l_MainInfoCard__header_container{
    --l-Dday-container-margin-bottom: 24px;

    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;

    margin-bottom: var(--l-Dday-container-margin-bottom);
}

.l_MainInfoCard__title_container{
    width: 100%;

    display: flex;
}

.l_MainInfoCard__body_container{
    width: 100%;
    height: auto;
    display: flex;
    flex-direction: column;

    &.fold_mode{
        transition: height 0.5s ease; 
        height: 0;

        &.open{
            height: 500px;
        }
    }  
}

.l_MainInfoCard__children_container_open_btn{
    width: 25px;
    height: 25px;
}

@media (min-width: 700px){
    .l_MainInfoCard__header_container{
        --l-Dday-container-margin-bottom: 34px;
    }
}