.l_TitleBtnWrap_main{
    width: 100%;
    
    /* 필터의 값이 없을때, 높이가 설정되어있지 않으면 TitleBtnWrap가 가늘어져서 보기에 좋지 않다. */
    /* SwipeTextItem의 높이가 달라지는등의 이슈가 생기면 값을 직접 수정해주자. */
    height: 43px;
    
    /* 오버플로우 컨트롤이 필요없는곳인데, 괜히 설정해서 불필요한 스크롤바만 생겼다. */
    /* overflow: auto; */
    
    position: relative;
    flex-shrink: 0;
    display: flex;
    flex-direction: row;
    align-items: center;
}

.l_TitleBtnWrap_swipeLineContainer{
    width: 0;
    flex-grow: 1;
}

/* SD (854×480) */
@media (min-width: 700px) {
    .l_TitleBtnWrap_main{
        /* 모바일에서 title의 Text 컴포넌트가 스타일상으로 숨겨지는데, 이때 gap이 남아있으면 불필요한 공간이 생긴다. -> web일때만 gap을 설정 */
        gap: 40px;
    }
}