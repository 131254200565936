.l_PaymentDetail_main{
    --l-padding: 20px 24px 20px 24px;
    --l-border-radius: 0 0 20px 20px;

    width: 100%;
    background-color: #F5F8F9;
    display: flex;
    flex-direction: column;
    padding: var(--l-padding);
    border-radius: var(--l-border-radius);
    box-sizing: border-box;
    gap: 14px;
}

.l_Hr{
    border: 1px solid var(#D5DDDE);
}

@media (min-width: 700px) {
    .l_PaymentDetail_main{
        --l-padding: 20px 50px 20px 50px;
        --l-border-radius: none;
    }
}