.l_OvalIconButton_main{
    border-radius: 50px;
    padding: 8px 14px 8px 14px;
    border: 1px solid var(--g-color-primary);
    background-color: white;

    &.preset{
        &.detail_view{
            border-color: var(--g-color-secondary);
        }

        &.upload{
            border-color: var(--g-color-primary);
        }

        &.delete{
            border-color: var(--g-color-red-gray);
        }
    }
}

.l_OvalIconButton_innerContainer{
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 4px;
}

.l_OvalIconButton_iconContainer{
    width: 16px;
    height: 16px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.l_OvalIconButton_iconImg{
    width: 100%;
    height: 100%;
    object-fit: contain;
}