.l_theme_book_management_main{
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column-reverse;
    align-items: center;
    gap: var(--g-gap);

    overflow: auto;
}

.l_theme_book_management__main_container{
    --l-width: 100%;

    width: var(--l-width);
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: white;
}

.l_title_container{
    width: 100%;
    box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.05);
}

.l_btn_container{
    width: 100%;
    /* box-shadow: inset 0px 4px 6px rgba(0, 0, 0, 0.05); */
    box-shadow: 0px -6px 10px rgba(0, 0, 0, 0.05);
}

/* SD (854×480) */
@media (min-width: 700px) {
    .l_theme_book_management_main{
        height: 100%;
        flex-direction: row;
        align-items: flex-start;
    }

    .l_theme_book_management__main_container{
        --l-width: auto;
        /* min-width: var(--g-textediter-width); */
        /* max-width: var(--g-textediter-width); */

        /* CardContainer에 감싸진경우 CardContainer의 좌, 우 패딩만큼 영역을 키워준다. */
        min-width: calc(var(--g-textediter-width) + calc(var(--g-card-padding-mobile) * 2));
        max-width: calc(var(--g-textediter-width) + calc(var(--g-card-padding-mobile) * 2));

        height: 100%;
    }

    .l_text_editer_quill_container{
        width: 100%;
        height: 0;
        flex-grow: 1;
        overflow: hidden;
    }
}