.l_swipe_line_main{
    width: 100%;
    height: 100%;

    position: relative;
    
    display: flex;
    justify-content: space-between;
    align-items: flex-end;

    gap: var(--g-gap-grid);
}

.l_move_btn_container{
    position: absolute;
    top: 50%;
    transform: translate(0, -50%);

    height: 100%;
    aspect-ratio: 1/1;

    display: flex;
    align-items: center;

    &.left{
        background: linear-gradient(to right, rgba(255, 255, 255, 0.8), rgba(255, 255, 255, 0));
        left: 0;
        z-index: 1;
    }

    &.right{
        background: linear-gradient(to left, rgba(255, 255, 255, 0.8), rgba(255, 255, 255, 0));
        right: 0;
        z-index: 1;
    }
}

.l_arrow_img{
    height: 100%;
}

.l_swipe_view{
    
    width: 100%;
    
    position: relative;
    
    display: flex;
    align-items: center;
    gap: var(--g-gap);
    overflow: hidden;
}

.l_swipe_data{
    display: flex;
    align-items: center;
    gap: 8px;
    transition: transform 0.3s ease-out;
    flex-wrap: nowrap;
    position: absolute;
}

/* SD (854×480) */
@media (min-width: 700px) {

    .l_arrow_img{

        &.size{

            &.web{
                &.full{
                    height: 100%;
                }
                
                &.middel{
                    height: 70%;
                }
                
                &.small{
                    height: 50%;
                }
            }

        }

    }

}