.l_theme_list_main{
    width: 100%;
    height: auto;

    margin-top: 10px;
}

.l_expandable_component_main {
    width: 100%;
    height: auto;
    display: flex;
    flex-direction: column;
    gap: 20px;
}

.l_button_container{
    width: auto;
    display: flex;
    align-items: center;
}

.l_node_container {
    width: 100%;
    height: auto;
}